@import '../../../styles/reference';

.RowDetails {
  display: flex;

  &:not(:last-child) {
    margin-bottom: $spacing-l;
  }

  &__Heading {
    width: 25%;
    min-width: rem-calc(130px);
  }

  &__Body {
    display: inline-block;
    width: 75%;
    margin-left: $spacing-m;

    > span {
      margin-right: $spacing-s;
    }

    button {
      display: inline-block;
      font-weight: $bolder-text;
    }
  }

  p {
    margin-bottom: 0;
  }
}
