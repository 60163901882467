@import '../../../../styles/reference';

.BillsSummary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-s $spacing-s;
  margin-bottom: $spacing-l;

  @media (min-width: ($breakpoint-xxs)) {
    gap: $spacing-m $spacing-m;
    margin-bottom: $spacing-xl;
  }

  .Card {
    margin-bottom: 0;
    padding: $spacing-m;

    p {
      margin-bottom: $spacing-xs;
      color: $pds-grey-fg-light;
      font-size: 14px;

      @media (min-width: ($breakpoint-xxs)) {
        margin-bottom: $spacing-s;
        font-size: 16px;
      }
    }

    .Amount {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;

      @media (min-width: ($breakpoint-xxs)) {
        font-size: 24px;
      }
    }
  }
}
