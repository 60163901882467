@import '../../../styles/reference';

.FullPageCampaign {
  position: relative;
  height: 100%;
  overflow-y: auto;

  &__CloseBtn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    margin: $spacing-l;

    @media (min-width: $breakpoint-m) {
      margin: rem-calc(36px);
    }
  }
}
