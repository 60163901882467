@import '../../../styles/reference';

.Contract {
  display: flex;
  flex-direction: column;
  max-width: $mobile-min-width;
  padding: $spacing-l 0;
  text-align: center;
  row-gap: $spacing-m;

  &Illustration {
    margin: 0 auto;
  }

  &ConfirmButton {
    width: 100%;
    margin-top: $spacing-m;
  }

  &Subtitle {
    margin-bottom: 0;
  }

  &DatePicker {
    input {
      text-align: center;
    }
  }
}
