@import '../../../../../styles/reference';

.AccountSyncSuccessModal {
  display: flex;
  flex-direction: column;

  &Heading {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }

  @media (max-width: $breakpoint-s) {
    align-items: center;
    margin-top: 50%;
    text-align: center;
  }

  &Footer {
    /* stylelint-disable-next-line selector-class-pattern */
    :global(.ba-button.medium) {
      @media (min-width: $breakpoint-s) {
        width: rem-calc(139px);
      }
    }
  }
}
