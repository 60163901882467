@import '../../styles/reference';

.FullScreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $max-z-index;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-base-white);
}
