@import '../../../../styles/reference';

.LoanAwaitingModal {
  &Icon {
    margin-bottom: $spacing-l;
  }

  &Title {
    margin-bottom: $spacing-m;
    font-size: rem-calc(24px);
  }

  &Text {
    margin-bottom: $spacing-xxl;
    line-height: rem-calc(24px);
  }
}
