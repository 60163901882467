@import '../../../../../styles/reference';

.DisconnectIntegrationModal {
  display: flex;
  flex-direction: column;

  &Heading {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }

  @media (max-width: $breakpoint-s) {
    align-items: center;
    margin-top: 50%;
    text-align: center;
  }
}
