@import '../../../../styles/reference';

.react-select {
  display: flex;
  flex-direction: column;
  background-color: $pds-color-white;

  &__label {
    margin-bottom: 0;
    color: $pds-grey-fg-light;
  }

  /* not able to inject typography classnames into the react-select custom components */
  &__placeholder,
  &__option,
  &__single-value {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);

    @media (min-width: $breakpoint-s) {
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
    }
  }

  &__option {
    display: flex;
    align-items: center;
    padding: $spacing-m $spacing-l;
    border-radius: $curve-2;
  }

  &__error {
    margin-top: $spacing-xs;
    color: $pds-color-red-600;
  }

  &__message {
    margin-top: $spacing-xs;
  }

  .error {
    .react-select {
      &__control {
        box-shadow: inset 0 -2px 0 $pds-color-red-600;
      }
    }
  }
}
