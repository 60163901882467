@import '../../../../styles/reference';

.ProductPageAlert {
  padding-right: rem-calc(24px);
  padding-left: rem-calc(24px);
  background-color: var(--color-background-upsell-default);
  border: $curve-2 solid $pds-grey-light;
  border-radius: rem-calc(4px);

  @media (max-width: ($breakpoint-ms)) {
    padding-right: rem-calc(14px);
    padding-left: rem-calc(14px);
  }

  &FullBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &Description {
    font-weight: $bolder-text;
    font-size: $spacing-m;
    line-height: $spacing-l;
  }

  &CTA {
    line-height: $spacing-l;
  }
}
