@import '../../../../styles/reference';

.card-activate-success-modal {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__heading {
    margin-top: $spacing-l;
  }
}
