@import '../../../../styles/reference';

.IntegrationCard {
  &Container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  &Icon {
    padding-right: $spacing-m;
  }

  &Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &Copy {
      display: flex;
      flex-direction: row;
      gap: $spacing-xs;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      p {
        margin: 0;
      }

      @media (max-width: ($breakpoint-s)) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }

  &Button {
    align-self: flex-start;
  }
}
