@import '../../../../styles/reference';

.BillPaySection {
  &Heading {
    margin-bottom: $spacing-m;
    font-weight: $bolder-text;
    font-size: rem-calc(20px);
  }

  &Skeleton {
    &Loader {
      display: block;
    }

    &SectionBody {
      width: 100%;
      height: $spacing-l;
      margin: $spacing-l 0;
    }

    &SectionTitle {
      width: rem-calc(200px);
      height: $spacing-l;
    }
  }
}
