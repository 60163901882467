@import '../../../../styles/reference';

.timeout-modal {
  &__icon {
    max-width: rem-calc(135px);
    margin: rem-calc(30px) auto rem-calc(28px);
  }

  &__title {
    margin-bottom: $spacing-m;
  }

  &__text {
    margin-bottom: rem-calc(24px);
    line-height: rem-calc(24px);
  }
}
