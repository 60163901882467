@import '../../../../styles/reference';

.payanyone-dropdown {
  &__menu {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    max-height: rem-calc(374px);
    margin-top: $spacing-s;
    overflow: auto;
    background: $pds-color-white;
    border-radius: $curve-2;
    box-shadow: 0 7px 24px rgba($pds-color-black, 0.3);
    animation: fadeinmenu 0.15s ease-in;

    &-button {
      display: flex;
      width: 100%;
      border-bottom: 2px solid $pds-color-neutral-100;

      button {
        display: flex;
        margin: $spacing-m $spacing-l $spacing-m auto;
      }
    }

    &__option {
      display: flex;
      align-items: center;
      width: 100%;
      padding: $spacing-m $spacing-l;
      cursor: pointer;

      &-description {
        display: flex;
        flex-direction: column;
        margin-left: $spacing-m;
      }

      &-subheader {
        color: $pds-grey-fg-light;
      }

      &-delete {
        display: none;
        margin-left: auto;
      }

      &:hover {
        background-color: $pds-color-neutral-100;

        .payanyone-dropdown {
          &__menu {
            &__option-delete {
              display: flex;
            }
          }
        }
      }

      &:not(:first-child, :last-child) {
        border-bottom: $spacing-xxs solid $pds-color-neutral-100;
      }
    }
  }
}
