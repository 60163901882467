@import '../../../../styles/reference';

.ConfirmBillsAlert {
  &Container {
    position: relative;
    margin-bottom: $spacing-l;
    background-color: var(--color-background-info-default);
    border-color: var(--color-background-info-hovered);
  }

  &Body {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $breakpoint-s) {
      flex-direction: row;
    }
  }

  &Link {
    margin: $spacing-s auto 0 0;

    @media (min-width: $breakpoint-s) {
      margin: 0 0 0 auto;
    }
  }
}
