@import '../../styles/reference';

.FullScreenAnimation {
  color: var(--color-icon-default);

  &Title {
    margin: $spacing-xxl 0;
    font-weight: $font-weight-bold;
    font-size: rem-calc(24px);
    text-align: center;

    @media (min-width: ($breakpoint-s)) {
      font-size: rem-calc(34px);
    }
  }

  &Subtitle {
    margin-bottom: 0;
    font-size: rem-calc(16px);
    text-align: center;

    @media (min-width: ($breakpoint-s)) {
      font-size: rem-calc(20px);
    }
  }
}
