@import '../../../styles/reference';

/* variables */
$tab-underline-height: 2px;

.react-tabs {
  width: 100%;

  &__tab-list {
    display: flex;
    margin: 0 0 $spacing-l;
    border-bottom: none;
    outline: none;
  }

  &__tab {
    position: relative;
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    font-weight: $bolder-text;
    font-size: $spacing-m;
    text-align: center;
    text-decoration: none;
    list-style: none;
    background-color: transparent;
    border: none;
    cursor: pointer;

    b {
      color: $pds-color-green-700;
    }
  }

  &.primary-fitted {
    .react-tabs {
      &__tab {
        width: 100%;
      }
    }
  }

  &.secondary-small,
  &.secondary-large {
    .react-tabs {
      &__tab-list {
        display: flex;
        justify-content: space-evenly;
        border: 2px solid $pds-color-neutral-100;
        border-radius: $curve-4;
      }

      &__tab {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: rem-calc(12px) 0;
        color: $pds-grey-fg-lighter;
        background-color: $pds-color-neutral-100;

        &:first-child {
          border-radius: $curve-4 0 0 $curve-4;
        }

        &:last-child {
          border-radius: 0 $curve-4 $curve-4 0;
        }

        &--selected {
          color: $pds-color-green-700;
          background-color: $pds-color-white;
        }

        span {
          display: flex;
          align-self: center;
        }
      }
    }
  }

  &.primary,
  &.primary-fitted {
    ul {
      padding-left: 0;
      list-style: none;
    }

    .react-tabs {
      &__tab-list {
        box-shadow: inset 0 rem-calc(-$tab-underline-height) 0 $pds-color-neutral-100;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      &__tab {
        color: $pds-grey-fg-light;
        border-bottom: rem-calc($tab-underline-height) solid transparent;

        span {
          display: inline-block;
          padding: rem-calc(6px) rem-calc(4px);
          border-radius: 6px;
        }

        &:focus {
          outline: none;

          span {
            box-shadow: inset 0 0 0 rem-calc($tab-underline-height) $outline-color;
          }
        }

        &--selected {
          color: $pds-color-neutral-800;
          border-bottom-color: $pds-brand-cta;
        }

        &:hover:not(&--selected) {
          color: $pds-color-neutral-600;
          border-bottom-color: $pds-color-neutral-300;
        }
      }
    }
  }

  &.secondary-small {
    .react-tabs {
      &__tab-list {
        height: rem-calc(34px);
      }
    }
  }

  &.secondary-large {
    .react-tabs {
      &__tab-list {
        height: rem-calc(48px);
        padding-inline-start: 0;
      }
    }
  }
}
