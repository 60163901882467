@import '../../../styles/reference';

.BusinessAccountCVPCard {
  &Disclaimer {
    padding: rem-calc(4px);

    @media (min-width: $breakpoint-s) {
      padding: rem-calc(12px);
    }

    &Title {
      @media (min-width: $breakpoint-s) {
        margin-bottom: rem-calc(20px);
        font-size: rem-calc(20px);
      }

      margin-bottom: $spacing-s;
      font-size: rem-calc(16px);
    }

    &Description {
      @media (min-width: $breakpoint-s) {
        margin-bottom: $spacing-m;
        font-size: rem-calc(16px);
      }

      color: var(--color-neutral-800);
      font-size: rem-calc(14px);
    }

    &CodeContainer {
      display: flex;
      align-items: center;
    }

    &Code {
      width: rem-calc(96px);
      margin-right: $spacing-m;
    }

    &CodeText {
      @media (min-width: $breakpoint-s) {
        margin-bottom: $spacing-s;
        font-size: rem-calc(16px);
      }

      margin-bottom: $spacing-m;
      color: var(--color-neutral-800);
      font-weight: $bolder-text;
      font-size: rem-calc(14px);
    }

    &DownloadLink {
      img {
        width: rem-calc(100px);
        height: rem-calc(34px);
        margin-right: $spacing-s;
      }
    }
  }

  &ButtonContainer {
    display: flex;
    justify-content: flex-end;
    @media (min-width: $breakpoint-s) {
      margin-top: $spacing-l;
    }

    margin-top: $spacing-m;
  }

  &Button {
    /* stylelint-disable-next-line selector-class-pattern */
    &:global(.ba-button.secondary-outline) {
      min-width: 100%;
      padding: rem-calc(12px) rem-calc(23px);
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
      @media (min-width: $breakpoint-s) {
        min-width: rem-calc(278px);
      }
    }
  }
}

.Divider {
  height: rem-calc(1px);
  @media (min-width: $breakpoint-s) {
    margin: rem-calc(20px) 0;
  }

  margin: rem-calc(16px) 0;
  color: var(--color-neutral-200);
}
