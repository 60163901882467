@import '../../../styles/reference';

.left-nav {
  z-index: 99;
  display: flex;
  flex-basis: rem-calc(262px);
  flex-direction: column;
  flex-shrink: 0;
  width: rem-calc(262px);
  min-height: 100%;
  font-size: rem-calc(20px);
  border-right: 2px solid $pds-color-neutral-200;
  transition: 0.2s ease;

  @media (max-width: $breakpoint-l) {
    &:not(.close-nav) {
      position: fixed;
      left: 0;
      background-color: $pds-color-white;

      /* if border colour isn't set, transition is buggy */
      border-right: 2px solid transparent;
    }
  }

  &__logo-nav {
    display: flex;
    place-content: center space-between;
    align-items: center;
    height: $spacing-xxxl;
    margin: $spacing-l 0;
    padding-right: rem-calc(12px);
    padding-left: $spacing-l;
  }

  &__logo {
    align-self: center;
  }

  &__toggle-nav {
    display: flex;
    padding: $spacing-s;
    border-radius: $curve-4;
    transform: rotate(180deg);
    cursor: pointer;

    svg {
      width: rem-calc(20px);
      height: rem-calc(20px);

      &:last-child {
        display: none;
      }

      @media (max-width: $breakpoint-m) {
        &:first-child {
          /* change icon to cross */
          display: none;
        }

        &:last-child {
          display: flex;
          width: rem-calc(24px);
          height: rem-calc(24px);

          path {
            fill: $pds-grey-fg-light;
          }
        }
      }
    }

    &:hover {
      background-color: $pds-color-neutral-200;
    }
  }
}

.left-nav.close-nav {
  flex-basis: rem-calc(80px);
  width: rem-calc(80px);

  /* if border colour isn't set, transition is buggy */
  border-right: 2px solid transparent;
  box-shadow: 0 0 15px rgba($pds-color-neutral-400, 0.3002);

  /* to allow for sliding nav from the left of the screen */
  @media (max-width: $breakpoint-m) {
    position: absolute;
    left: -100%;
  }

  .left-nav {
    &__logo,
    &__page-name {
      display: none;
    }

    &__toggle-nav {
      padding: $spacing-s;
      transform: rotate(0deg);

      svg {
        width: rem-calc(20px);
        height: rem-calc(20px);
      }
    }

    &__logo-nav {
      justify-content: center;
      padding-right: rem-calc(20px);
      padding-left: rem-calc(20px);
    }

    &__link {
      height: rem-calc(52px);
      padding-right: rem-calc(10px);
      padding-bottom: rem-calc(38px);
    }

    &__menu {
      width: rem-calc(52px);
      margin-left: rem-calc(8px);
      padding-left: rem-calc(12px);
    }
  }
}

/* top nav */
.top-nav {
  display: none;
  padding: rem-calc(20px) $spacing-m;
  box-shadow: 0 4px 15px rgba($pds-color-neutral-400, 0.3);

  @media (max-width: $breakpoint-m) {
    display: flex;
  }

  &__logo,
  &__menu-icon {
    align-self: center;
  }

  &__logo {
    margin-left: rem-calc(20px);
  }

  &__menu-icon {
    cursor: pointer;
  }
}

/* remove shadow when buttons are sticky on mobile only */
.top-nav.no-box-shadow {
  @media (max-width: $breakpoint-s) {
    box-shadow: none;
  }
}

.nav-backdrop {
  @media (max-width: $breakpoint-l) {
    position: absolute;
    inset: 0;

    /* needs to be below 99 for left nav */
    z-index: 90;
    background: rgba($pds-color-black, 0.4);
  }
}
