@import '../../../../styles/reference';

.NoBillsCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.NoBillsCardContent {
  max-width: rem-calc(386px);
  text-align: center;

  .Title {
    color: var(--color-text-default);
    font-weight: $bolder-text;
    font-size: rem-calc(18px);
  }

  .Email {
    display: block;
    margin-bottom: $spacing-m;
    padding: rem-calc(12px);
    color: var(--color-neutral-800);
    text-align: center;
    background-color: var(--color-green-50);
    border-radius: rem-calc(4px);

    & > span {
      font-weight: $bolder-text;
    }
  }

  .CopyText {
    margin-left: $spacing-xs;
  }

  .Description {
    letter-spacing: rem-calc(-0.176px);
  }

  .Support {
    color: var(--color-neutral-500);
    font-size: rem-calc(14px);

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .InlineButton {
    display: inline-block;
  }
}
