@import '../../../../styles/reference';

.TitleLoader,
.ValueLoader {
  width: rem-calc(89px);
  height: rem-calc(20px);
  margin-bottom: $spacing-s;
}

.ValueLoader {
  margin-bottom: $spacing-m;
}

.ProgressBarLoader {
  height: $spacing-m;
  margin-bottom: rem-calc(20px);
}
