@import '../../../../../styles/reference';

.Bills {
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $spacing-l 0;

    @media (min-width: $breakpoint-m) {
      margin-top: 0;
    }
  }

  &PageActions {
    align-self: flex-start;
  }
}
