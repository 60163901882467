@import '../../../../styles/reference';

.new-card-success-modal {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__icon {
    margin-bottom: $spacing-l;
  }

  &__address {
    font-weight: $bolder-text;
  }

  &__subheading {
    margin-bottom: $spacing-l;
  }

  .pds-lead {
    margin-bottom: $spacing-m;
  }

  [class^='Card'] {
    p.pds-text {
      color: $pds-grey-fg-light;
    }
  }

  p {
    margin-bottom: 0;
  }

  h4 {
    margin-bottom: $spacing-m;
  }

  .input-wrapper {
    margin-bottom: $spacing-l;
  }
}
