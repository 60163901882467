@import '../../../../styles/reference';

.transactions {
  display: flex;
  flex-direction: column;

  ul {
    padding-left: 0;
    list-style: none;
  }

  &__list {
    &#{&} {
      margin: 0;
    }

    &--item-month {
      margin-bottom: 0;
      padding-top: $spacing-l;
      padding-left: rem-calc(12px);

      @media (min-width: $breakpoint-s) {
        padding-left: $spacing-l;
      }

      @media (max-width: $breakpoint-s) {
        + .transaction-item-container {
          .transaction-item {
            padding-top: rem-calc(12px);
          }
        }
      }
    }
  }

  button {
    align-self: center;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__empty-list {
    display: flex;
    padding-top: $spacing-l;
    padding-left: $spacing-l;
  }
}
