@import '../../../../styles/reference';

.ErrorModal {
  display: flex;
  flex-direction: column;

  &Heading {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }

  span {
    margin-bottom: $spacing-xxl;
  }
}
