@import '../../../../styles/reference';

.activity {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 $spacing-xxxl;

  [class^='Card'] {
    padding: 0;
  }

  &__wrapper {
    width: 100%;
    margin-bottom: $spacing-l;
    overflow: hidden;
    border: $spacing-xxs $pds-color-neutral-100 solid;
    border-radius: $curve-4;

    @media (max-width: $breakpoint-s) {
      margin-bottom: $spacing-xl;
    }
  }

  .ba-button {
    &.link {
      color: $pds-color-neutral-800;
      text-underline-offset: $spacing-s;
    }
  }
}
