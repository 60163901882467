/* stylelint-disable selector-class-pattern */
@import '../../../../styles/reference';

:global {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :local(.ProductBanner) {
    &.banner {
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: $spacing-m;
      padding: calc($spacing-m - 2px);

      @media (min-width: $breakpoint-ms) {
        justify-content: space-between;
        padding: calc($spacing-m - 2px) calc($spacing-l - 2px);
      }
    }

    &.banner__body {
      height: rem-calc(92px);
      @media (min-width: $breakpoint-ms) {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
      }
    }

    &.banner__info {
      margin-bottom: $spacing-xs;
    }

    &.banner__info-icon {
      svg {
        width: rem-calc(48px);
        height: rem-calc(48px);
      }

      margin-top: 0;
      margin-right: $spacing-s;

      @media (min-width: ($breakpoint-ms)) {
        svg {
          width: rem-calc(64px);
          height: rem-calc(64px);
        }

        margin-right: $spacing-l;
      }
    }

    &.banner__btn.ba-button.medium {
      align-self: flex-start;
      min-width: rem-calc(76px);
      height: rem-calc(24px);
      font-size: rem-calc(14px);
      @media (min-width: ($breakpoint-ms)) {
        align-self: flex-end;
        margin-left: auto;
      }
    }
  }
}
