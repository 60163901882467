@import '../../../../styles/reference';

.overdraft {
  .banner {
    &__info {
      span {
        width: 80%;
      }

      @media (min-width: $breakpoint-s) {
        span {
          width: max-content;
        }
      }
    }
  }
}
