@import '../../../../styles/reference';

.ErrorModal {
  &Body {
    display: flex;
    align-items: center;
    height: 100%;

    @media (min-width: $breakpoint-s) {
      margin-top: rem-calc(-36px);
    }
  }

  &Contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (min-width: $breakpoint-s) {
      align-items: start;
      text-align: left;
    }

    &Heading {
      margin-top: $spacing-l;
      margin-bottom: $spacing-m;
    }

    &Body {
      margin-bottom: $spacing-xxl;
      letter-spacing: -0.176px;
    }
  }
}
