@import '../../styles/reference';

.TitleWithStatus {
  display: flex;
  align-items: center;
  justify-self: center;

  &Title {
    margin: 0 $spacing-m 0 0;
    font-size: rem-calc(28px);
    line-height: rem-calc(42px);
    @media (min-width: $breakpoint-m) {
      font-size: rem-calc(32px);
    }
  }
}
