@import '../../../../../styles/reference';

.NzMobileCampaign {
  &__Container {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @media screen and (min-width: $breakpoint-m) {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 100vh;
      overflow: hidden;
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100vw;
    height: auto;

    @media screen and (min-width: $breakpoint-m) {
      min-width: 50vw;
      height: 100vh;
      padding: 0;
    }

    &__Image {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100vw;
      height: auto;
    }

    &__Copy {
      width: 100vw;
      height: max-content;
      padding: 0 rem-calc(20px);

      @media screen and (min-width: $breakpoint-xxs) {
        padding: 0 $spacing-l;
      }

      @media screen and (min-width: $breakpoint-m) {
        width: rem-calc(650px);
      }

      &_Logo {
        img {
          width: rem-calc(130px);
          height: auto;
        }

        @media screen and (min-width: $breakpoint-xxs) {
          img {
            width: rem-calc(150px);
          }
        }

        @media screen and (min-width: $breakpoint-m) {
          img {
            width: rem-calc(213px);
          }
        }
      }

      &_Heading {
        width: 100%;
        margin: $spacing-l 0;
        font-weight: 700;
        font-size: rem-calc(28px);
        line-height: rem-calc(32px);
        letter-spacing: 0;

        @media screen and (min-width: $breakpoint-xxs) {
          font-size: rem-calc(34px);
          line-height: rem-calc(42px);
        }

        @media screen and (min-width: $breakpoint-m) {
          width: 85%;
          margin: $spacing-xl 0;
          font-size: rem-calc(48px);
          line-height: rem-calc(52px);
        }
      }

      &_Body {
        width: 100%;
        margin-bottom: 0;
        line-height: $spacing-l;
        @media screen and (min-width: $breakpoint-m) {
          width: 80%;
        }
      }
    }
  }

  &__Animation__Container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__Hidden {
      display: none;
    }

    @media screen and (min-width: $breakpoint-m) {
      left: -20%;
    }
  }

  &__QR_Code {
    display: none;

    @media screen and (min-width: $breakpoint-xxs) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: rem-calc(533px);
      margin: $spacing-xl 0;
      padding: $spacing-l;
      background: $pds-brand-grey-light;

      span {
        font-weight: 600;
        font-size: rem-calc(20px);
        font-style: normal;
        line-height: rem-calc(28px);
      }

      img {
        width: rem-calc(87px);
        height: rem-calc(87px);
        margin-right: $spacing-l;
      }
    }

    @media screen and (min-width: $breakpoint-m) {
      width: rem-calc(443px);
      margin-top: $spacing-xxl;
      padding: $spacing-m;

      img {
        width: rem-calc(128px);
        height: rem-calc(128px);
        margin-right: $spacing-xl;
      }
    }
  }

  &__App_Store__Logo {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    margin: $spacing-xl 0;

    @media screen and (min-width: $breakpoint-xxs) {
      display: none;
    }

    @media screen and (min-width: $breakpoint-m) {
      display: flex;
      width: rem-calc(433px);
      margin: $spacing-xxxl 0 0;
    }

    &__Link {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      display: inline-block;
      margin-right: $spacing-m;

      @media screen and (min-width: $breakpoint-m) {
        height: $spacing-4xl;
        margin-right: 0;
      }
    }
  }
}
