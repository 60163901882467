@import '../../../../styles/reference';

.payments {
  ul {
    padding-left: 0;
    list-style: none;
  }

  &__list {
    &__list {
      margin: 0;
    }

    &--spinner {
      width: rem-calc(35px);
      margin: 0 auto;
    }

    &--item-month {
      padding-top: $spacing-m;
      padding-left: $spacing-m;

      @media (min-width: ($breakpoint-s)) {
        padding-top: $spacing-l;
        padding-left: $spacing-l;
      }
    }
  }

  button {
    padding-right: 0;
    padding-bottom: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__empty-list {
    display: flex;
    margin-bottom: 0;
    padding: $spacing-l;
    padding-right: 0;
  }
}
