@import '../../styles/reference';

.CopyTextWithLabel {
  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-m;
    background-color: var(--color-green-50);
  }

  &Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Label {
    padding-right: $spacing-xs;
    font-weight: 600;
  }

  &CopyIcon {
    margin-top: -$spacing-xs;
  }
}
