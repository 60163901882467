@import '../../../../styles/reference';

.Icon {
  margin-bottom: $spacing-m;
}

.Header {
  display: flex;
  align-items: flex-start;
}

.Email {
  display: block;
  margin-bottom: $spacing-l;
  padding: rem-calc(12px);
  text-align: center;
  background-color: var(--color-green-50);

  & > span {
    font-weight: 600;
  }
}

.CopyText {
  margin-left: $spacing-xs;
}

.Description {
  letter-spacing: -0.176px;
}

.Support {
  color: var(--color-neutral-500);
  font-size: rem-calc(14px);

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.Footer {
  margin-top: $spacing-s;
}

.InlineButton {
  display: inline-block;
}
