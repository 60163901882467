@import '../../../../styles/reference';

.LoadMoreButton {
  margin-bottom: $spacing-l;

  .Button {
    margin: auto;
    color: $pds-color-neutral-800;
    line-height: rem-calc(28px);
    text-decoration: underline;
    text-underline-offset: $spacing-s;

    &:not(.Disabled):hover {
      text-decoration: none;
    }
  }
}
