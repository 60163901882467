@import '../../../styles/reference';

// temporary button styling
.ba-button.logout-button {
  margin-left: auto;
  padding: rem-calc(12px) $spacing-l;
  color: $pds-grey-fg-light;
  font-weight: 400;

  @media (min-width: $breakpoint-l) {
    position: relative;
    left: $spacing-l;
  }

  &:not(.disabled):hover {
    text-decoration: none;
    background-color: $pds-color-neutral-200;
  }
}

.ba-button.logout-button-minimal {
  margin-left: auto;

  &:hover {
    background-color: $pds-color-neutral-200;
  }

  svg {
    margin-right: 0;
  }
}
