@import '../../../../styles/reference';

.ConfirmBillForm {
  &FieldToolTip {
    svg {
      margin-bottom: rem-calc(3px);
      color: $pds-grey-fg-light;
    }

    > div {
      bottom: 100%;

      * {
        width: max-content;
      }
    }
  }

  &PaymentFieldsSection {
    > div {
      height: auto;
      margin-bottom: $spacing-m;
      @media (min-width: $breakpoint-s) {
        margin-bottom: $spacing-l;
      }

      &:last-child {
        margin-bottom: 0;
      }

      input:disabled {
        background-color: $pds-color-white;
      }
    }
  }

  &PaymentMethod {
    &Details {
      p {
        display: flex;

        .Details {
          &Label {
            width: 50%;
            font-weight: 600;
          }

          &Value {
            display: inline-block;
            width: 50%;
            word-break: break-all;
          }
        }
      }
    }

    &Section {
      &Heading {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-s;
        font-size: rem-calc(16px);
        @media (min-width: $breakpoint-s) {
          font-size: rem-calc(20px);
        }

        img {
          padding-right: $spacing-s;
        }
      }

      &NoPayee {
        margin-bottom: 0;

        &Error {
          margin: $spacing-m 0 0 0;
          color: $pds-color-red-600;
        }
      }

      &AddPayeeButton {
        margin-top: $spacing-l;

        span {
          color: $pds-brand-secondary;
          text-decoration: underline;
        }
      }
    }
  }

  &SubmissionSection {
    margin-bottom: $spacing-xxxl;
    @media (min-width: $breakpoint-s) {
      margin-bottom: $spacing-l;
    }

    &Button {
      width: 100%;
      margin-bottom: $spacing-m;
    }

    &Disclaimer {
      margin: $spacing-m 0;
      color: $pds-color-neutral-500;
      font-size: rem-calc(12px);
      @media (min-width: $breakpoint-s) {
        margin: $spacing-l 0;
        font-size: rem-calc(14px);
      }
    }
  }
}
