@import '../../../../styles/reference';

@mixin table-cell {
  padding: $spacing-m;
  font-size: rem-calc(14px);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(16px);
  }
}

.TableDataCell {
  @include table-cell;

  min-width: max-content;
  max-width: rem-calc(160px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.TableHeaderCell {
  @include table-cell;

  color: $pds-grey-fg-light;
  font-weight: 600;
}
