@import '../../../../styles/reference';

.input-container {
  .icon {
    position: absolute;
    right: 2px;
    bottom: 6px;
    width: 20px;
    height: 20px;
  }
}
