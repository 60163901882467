@import '../../../../styles/reference';

.SuspendedAccountAlert {
  &Body {
    flex-grow: 0;
    width: 75%;

    @media (min-width: $breakpoint-s) {
      width: max-content;
    }
  }
}
