@import '../../../styles/reference';

[class^='Card'].loan-activity {
  padding: 0;

  .react-tabs {
    &__tab-panel [class^='Card'] {
      padding: 0;
      border: 0;
      border-radius: 0;
    }

    &__tab-list {
      margin: 0 $spacing-m;
      padding-top: $spacing-m;

      li {
        margin-right: rem-calc(17px);
        padding: 0;

        span {
          display: inline-block;
          margin-bottom: rem-calc(2px);
          padding: 0;
          line-height: rem-calc(24px);
          box-shadow: none;
        }
      }
      @media (min-width: $breakpoint-s) {
        li {
          margin-right: rem-calc(35px);
        }

        margin: 0 $spacing-l $spacing-s;
        padding-top: $spacing-l;
      }
    }

    &__tab {
      &:focus {
        span {
          box-shadow: none;
        }
      }
    }
  }
}
