@import '../../../../styles/reference';

.left-nav {
  .flip {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
  }

  &__page-name {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__new {
    &__badge {
      display: inline-block;
      margin-left: rem-calc(12px);
      line-height: 20px;
    }
  }

  &__text {
    &__badge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: rem-calc(28px);
      height: rem-calc(20px);
      margin-left: auto;
      font-size: rem-calc(12px);
      line-height: rem-calc(16px);
      border-radius: rem-calc(24px);
    }
  }

  &__trigger {
    display: flex;
    align-items: center;

    svg {
      min-width: rem-calc(24px);
      height: rem-calc(24px);
      margin-left: auto;

      path {
        fill: $pds-grey-fg-light;
      }
    }

    &-icon {
      svg {
        width: rem-calc(28px);
        height: rem-calc(28px);
        margin-right: rem-calc(16px);
      }
    }
  }

  &__sub {
    display: flex;
    align-items: center;
    min-width: rem-calc(180px);
    height: rem-calc(52px);
    margin-right: rem-calc(12px);
    margin-left: rem-calc(63px);
    padding-left: rem-calc(16px);

    &:hover {
      color: $pds-grey-fg-light;
      font-weight: $bolder-text;
      background-color: $pds-color-neutral-200;
      border-radius: 4px;
    }

    &.subnav-active {
      color: $pds-color-neutral-800;
    }
  }

  &__link {
    margin-bottom: $spacing-s;
    color: $pds-grey-fg-light;
    font-weight: normal;
    border-left: rem-calc(5px) solid transparent;
    outline: none;

    &:hover {
      color: $pds-grey-fg-light;
      font-weight: $bolder-text;
      text-decoration: none;

      @media (max-width: $breakpoint-l) {
        .left-nav {
          &__label {
            &__text {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &--active {
      margin-bottom: $spacing-s;
      color: $pds-color-neutral-800;
      font-weight: $bolder-text;
      border-left: rem-calc(5px) solid $pds-brand-cta;

      .left-nav {
        &__trigger {
          svg {
            path {
              fill: $pds-grey-fg-light;
            }
          }

          &-icon {
            svg {
              path {
                fill: $pds-brand-secondary;
              }
            }
          }
        }
      }

      &:hover {
        color: $pds-color-neutral-800;
        text-decoration: none;
      }
    }

    &.enter {
      transform: translateY(-100%);
      opacity: 0;
      transition: all 0.2s ease-in-out;

      &:hover {
        pointer-events: none;
      }
    }

    &.enter-active {
      transform: translateY(0%);
      opacity: 1;
      transition: all 0.2s ease-in-out;

      &:hover {
        pointer-events: none;
      }
    }

    &.exit {
      transform: translateY(0%);
      opacity: 1;
      transition: all 0.2s ease-in-out;

      &:hover {
        pointer-events: none;
      }
    }

    &.exit-active {
      transform: translateY(-100%);
      opacity: 0;
      transition: all 0.2s ease-in-out;

      &:hover {
        pointer-events: none;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    height: rem-calc(52px);
    margin-right: rem-calc(12px);
    margin-left: rem-calc(11px);
    padding-right: rem-calc(8px);
    padding-left: rem-calc(8px);

    &:hover {
      background-color: $pds-color-neutral-200;
      border-radius: 4px;
    }
  }
}

.close-nav {
  .left-nav {
    &__text,
    &__new {
      &__badge {
        margin-left: $spacing-xs;
      }
    }
  }
}
