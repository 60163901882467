@import '../../../styles/reference';

.date-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .dates {
    display: flex;
  }

  .datepicker {
    flex: 0 0 120px;
    margin-right: $spacing-m;
  }

  .hyphen {
    align-self: center;
    margin: 0 $spacing-m $spacing-s 0;

    @media (min-width: $breakpoint-s) {
      margin-bottom: 0;
    }
  }

  .button-row {
    margin-left: auto;
  }
}
