@import '../../../styles/reference';

.OverdraftDetail {
  &Container {
    margin-top: $spacing-l;
    padding-bottom: $spacing-l;
    border-bottom: $spacing-xxs solid $pds-color-neutral-200;
  }

  &Row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $spacing-m;
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);

    @media (min-width: $breakpoint-s) {
      justify-content: flex-start;
      width: 48%;
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &Heading {
    display: inline-block;
    width: max-content;
    font-weight: 700;

    @media (min-width: $breakpoint-s) {
      width: 70%;
    }
  }

  &Value {
    font-weight: 400;
    font-size: 16px;
  }
}
