@import '../../../styles/reference';

.loan-transactions {
  display: flex;
  flex-direction: column;

  &--month {
    padding: $spacing-m $spacing-m 0;

    @media (min-width: $breakpoint-s) {
      padding: $spacing-l $spacing-l 0;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  &__list {
    margin: 0;

    .transaction-item-container {
      padding: 0 rem-calc(12px);

      @media (min-width: $breakpoint-s) {
        padding: 0 $spacing-s;
      }
    }
  }

  button {
    align-self: center;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__empty-list {
    display: flex;
    padding-top: $spacing-l;
    padding-left: $spacing-l;
  }
}

.loantransactions {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 $spacing-xxxl;

  &__card[class^='Card'] {
    margin-bottom: $spacing-m;
    padding: $spacing-m 0 0;

    @media (min-width: $breakpoint-s) {
      margin-bottom: $spacing-l;
      padding: $spacing-l 0 0;
    }
  }

  &__card-no-title[class^='Card'] {
    padding: 0;
    border: none;
  }

  &__heading {
    margin-bottom: 0;
    margin-left: $spacing-m;
    line-height: $spacing-l;

    @media (min-width: $breakpoint-s) {
      margin-left: $spacing-l;
    }
  }

  ul {
    margin: $spacing-l $spacing-l 0 $spacing-l;
  }

  &__wrapper {
    width: 100%;
    overflow: hidden;
  }
}
