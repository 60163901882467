@import '../../styles/reference';

.CVPCardBody {
  display: block;

  @media screen and (min-width: $breakpoint-s) {
    margin-bottom: $spacing-m;
  }

  &__Media {
    max-width: 100%;
    margin-bottom: $spacing-l;
    @media screen and (min-width: $breakpoint-s) {
      margin-bottom: $spacing-xl;
    }
  }

  &__Heading {
    margin-bottom: $spacing-m;
    font-weight: 700;
    line-height: rem-calc(30px);
  }

  &__Description {
    margin-bottom: $spacing-s;
    letter-spacing: rem-calc(-0.176px);

    @media screen and (min-width: $breakpoint-s) {
      margin-bottom: $spacing-m;
    }
  }

  &__ValuePoints {
    padding: 0;
    list-style: none;

    &_Item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: rem-calc(12px);
      letter-spacing: rem-calc(-0.176px);

      svg {
        color: $pds-color-green-600;
      }

      * {
        display: inline-block;
      }

      span {
        max-width: calc(100% - 20px);
        font-size: rem-calc(14px);
        letter-spacing: rem-calc(-0.176px);
      }

      @media screen and (min-width: $breakpoint-s) {
        align-items: center;
        margin-bottom: $spacing-m;

        span {
          max-width: auto;
          margin-left: $spacing-m;
          font-size: rem-calc(16px);
        }
      }

      &_Check {
        padding-right: $spacing-xs;
      }
    }
  }

  &__Disclaimer {
    margin-top: $spacing-l;
    padding: rem-calc(12px);
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
    background-color: var(--color-neutral-50);
    border-radius: rem-calc(2px);
    @media screen and (min-width: $breakpoint-s) {
      padding: $spacing-m;
      font-size: rem-calc(14px);
      line-height: rem-calc(20px);
      border-radius: rem-calc(4px);
    }
  }
}
