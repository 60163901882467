@import '../../../styles/reference';

.PastStatements {
  h3 {
    margin-bottom: $spacing-l;
  }

  &Empty {
    margin-bottom: 0;
  }

  &Row {
    display: flex;
    margin-bottom: $spacing-m;
    border-bottom: 2px solid $pds-color-neutral-100;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .ButtonRow {
    margin-left: auto;
  }
}
