@import '../../styles/reference';
@import '../../styles/colors';

.Badge {
  padding: $spacing-xs rem-calc(6px);
  font-weight: 600;
  font-size: rem-calc(14px);
  border-radius: $curve-8;

  &.Primary {
    @include color-schema('primary');
  }

  &.Error {
    @include color-schema('error');
  }
}
