@import '../../../../styles/reference';

.TooltipLoader {
  padding-top: $spacing-xs;

  .TooltipContent {
    width: rem-calc(200px);
    height: $spacing-l;
  }
}
