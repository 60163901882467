@import '../../styles/reference';

.Table {
  color: $pds-color-neutral-800;

  // Notes: override the default table styles in reactstrap
  & > :not(caption) > * > * {
    border-color: $pds-grey-bg;
    border-bottom-width: rem-calc(2px);
  }

  &ShadowContainer {
    max-width: 100vw;
    overflow: auto;
    background-color: $pds-color-white;
    background-image:
      linear-gradient(to right, $pds-color-white, $pds-color-white),
      linear-gradient(to right, $pds-color-white, $pds-color-white),
      linear-gradient(
        to right,
        rgba($pds-color-neutral-200, 80%),
        rgba($pds-color-neutral-200, 0%)
      ),
      linear-gradient(to left, rgba($pds-color-neutral-200, 80%), rgba($pds-color-neutral-200, 0%));
    background-repeat: no-repeat;
    background-position:
      left center,
      right center,
      left center,
      right center;
    background-size:
      rem-calc(20px) 100%,
      rem-calc(20px) 100%,
      rem-calc(16px) 100%,
      rem-calc(16px) 100%;
    background-attachment: local, local, scroll, scroll;

    @media (min-width: $breakpoint-s) {
      background: none;
    }
  }
}
