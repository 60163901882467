@import '../../../styles/reference';

.TogglerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing-m;

  &WithRightLabel {
    width: 100%;
  }
}
