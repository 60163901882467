@import '../../styles/reference';

.banner {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;

  &[class^='Card'] {
    @media (min-width: ($breakpoint-s)) {
      padding: $spacing-m $spacing-l;
    }
  }

  .ba-button.medium {
    height: rem-calc(34px);
  }

  @media (min-width: ($breakpoint-s)) {
    flex-direction: row;
    align-items: center;

    .ba-button.medium {
      height: 3rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    margin-bottom: $spacing-m;
    @media (min-width: ($breakpoint-s)) {
      margin-bottom: 0;
    }

    &-heading {
      margin-bottom: $spacing-xs;
      font-weight: $bolder-text;
    }

    &-arrival {
      margin-right: $spacing-m;
      font-size: rem-calc(14px);
      line-height: rem-calc(20px);

      @media (min-width: ($breakpoint-s)) {
        margin-top: $spacing-m;
        margin-top: 0;
      }
    }

    &-icon {
      display: flex;
      margin-top: $spacing-xs;

      svg {
        width: rem-calc(64px);
        height: rem-calc(58px);

        @media (min-width: ($breakpoint-s)) {
          width: rem-calc(81px);
          height: rem-calc(75px);
        }
      }

      @media (min-width: ($breakpoint-s)) {
        margin-top: 0;
        margin-right: $spacing-m;
      }
    }
  }

  &__activation-link {
    margin-top: rem-calc(10px);
    padding: 0;
    text-align: left;
    border: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-s) {
      display: flex;
      flex-direction: row;
      align-content: space-between;
      width: 100%;
    }
  }

  &__button-section {
    display: flex;
    flex-direction: column-reverse;
    gap: $spacing-s;
    margin-right: auto;
    margin-left: 0;

    @media (min-width: $breakpoint-xs) {
      flex-direction: row-reverse;
    }

    @media (min-width: $breakpoint-s) {
      flex-direction: row;
      gap: $spacing-m;
      margin-right: 0;
      margin-left: auto;
    }
  }

  .ba-button {
    align-self: baseline;

    @media (min-width: $breakpoint-s) {
      align-self: center;
      min-width: rem-calc(142px);
    }

    .pds-text {
      white-space: nowrap;
    }
  }
}
