@use 'sass:map';
@import './reference';

$text-colors: (
  'primary': $pds-color-neutral-800,
  'error': $pds-color-neutral-800,
);
$background-colors: (
  'primary': $primary,
  'error': $pds-color-red-200,
);

@mixin color-schema($name) {
  color: map.get($text-colors, $name);
  background-color: map.get($background-colors, $name);
}
