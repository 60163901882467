@import '../../styles/reference';

.Expanded-content {
  margin-bottom: $spacing-l;

  @media (min-width: $breakpoint-s) {
    margin-bottom: $spacing-xl;
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    padding: 0;
    background: none;
    border: 0;
    border-bottom: 1px solid $pds-color-neutral-800;
  }

  &__title {
    margin-right: rem-calc(20px);
    margin-bottom: 0;
    color: $pds-color-neutral-800;
  }

  &__dropdown-icon {
    position: absolute;
    right: rem-calc(-6px);
    bottom: rem-calc(-1px);
    width: rem-calc(22px);
    height: rem-calc(22px);

    &[data-open='true'] {
      transform: rotate(180deg);
    }

    &[data-open='false'] {
      bottom: 0;
      transition: all 0.2s ease;
    }

    path {
      fill: $pds-color-neutral-800;
    }

    @media (min-width: $breakpoint-s) {
      bottom: rem-calc(1px);

      &[data-open='true'] {
        bottom: rem-calc(3px);
      }
    }
  }
}
