@import '../../styles/reference';

/* this mixin is for alert with exclamation mark and text */
@mixin color-icon($icon-color: $pds-color-neutral-900) {
  height: 100%;

  svg {
    margin-right: $spacing-s;
    color: $icon-color;
  }
}

@mixin alert($border-color, $bg-color, $font-color: $pds-color-neutral-900) {
  color: $font-color;
  background-color: $bg-color;
  border: $spacing-xxs solid $border-color;
}

$alert-line-height: rem-calc(20px);

.Alert {
  position: relative;
  display: flex;
  flex-grow: 0;
  align-items: flex-start;
  height: auto;
  margin-bottom: 0;
  padding: rem-calc(12px) rem-calc(16px);
  font-size: rem-calc(14px);
  line-height: $alert-line-height;
  border-radius: $curve-4;

  @media (min-width: $breakpoint-s) {
    align-items: center;
    min-height: rem-calc(48px);
  }

  &CloseButton {
    position: absolute;
    top: rem-calc(10);
    right: rem-calc(16);
    padding-left: $spacing-s;
    cursor: pointer;

    svg {
      path:first-of-type {
        stroke: $pds-color-neutral-500;
      }
    }
  }
}

// the default style is for the any icon and text alert
.Default {
  @include alert($pds-color-neutral-100, $pds-color-white);

  .AlertLogo {
    height: 100%;

    svg {
      margin-right: $spacing-s;
    }
  }
}

.Danger {
  @include alert($pds-color-red-100, $pds-color-red-50);

  .AlertLogo {
    @include color-icon($pds-color-red-600);
  }
}

.Warning {
  @include alert($pds-color-yellow-100, $pds-color-yellow-50);

  .AlertLogo {
    @include color-icon($pds-color-yellow-600);
  }
}

.Info {
  @include alert($pds-color-neutral-300, $pds-color-neutral-100);

  .AlertLogo {
    @include color-icon($pds-color-neutral-900);
  }
}

.Success {
  @include alert($pds-color-green-200, $pds-color-green-50);

  .AlertLogo {
    @include color-icon($pds-color-green-600);
  }
}

.Notification {
  @include alert(var(--color-background-info-hovered), var(--color-background-info-default));

  .AlertLogo {
    @include color-icon(var(--color-icon-info));
  }
}
