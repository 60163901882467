@import '../../styles/reference';

.Status {
  padding: $spacing-xs $spacing-s;
  font-weight: 600;
  font-size: rem-calc(14px);
  line-height: rem-calc(14px);
  border-radius: $spacing-l;

  &.Pending {
    color: $pds-color-neutral-700;
    background-color: $pds-color-yellow-100;
  }

  &.Cleared {
    color: $pds-color-neutral-700;
    background-color: $pds-color-green-200;
  }

  &.Primary {
    color: $pds-color-neutral-700;
    background-color: $pds-color-neutral-200;
  }

  &.Warning {
    color: $pds-color-neutral-700;
    background-color: $pds-color-red-200;
  }

  &.Error {
    color: $pds-color-neutral-700;
    background-color: $pds-color-red-200;
  }
}
