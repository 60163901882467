@import '../../../styles/reference';

.LoanUpcomingRepayment {
  &Container {
    display: block;

    @media (min-width: $breakpoint-s) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
