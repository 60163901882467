@import '../../../styles/reference';

.statement-account-info {
  display: flex;
  width: 100%;

  &__icon {
    display: none;
    margin-right: $spacing-s;

    @media (min-width: $breakpoint-xxs) {
      display: block;
    }

    @media (min-width: $breakpoint-s) {
      margin-right: $spacing-m;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;

    &:last-child {
      align-items: flex-end;
      margin-left: auto;
    }
  }

  &__available {
    color: $pds-grey-fg-light;
  }

  p {
    margin: 0;
  }
}
