@import '../../../../../styles/reference';

.payment-item {
  display: flex;
  align-items: center;
  margin-right: rem-calc(4px);
  margin-left: rem-calc(4px);
  padding-top: $spacing-m;
  padding-bottom: $spacing-m;
  @media (min-width: ($breakpoint-s)) {
    margin-right: rem-calc(12px);
    margin-left: rem-calc(12px);
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
  }

  &__hover-container {
    padding-right: $spacing-xs;
    padding-left: $spacing-xs;

    &:hover {
      background-color: $pds-color-neutral-100;
      border-radius: $spacing-xs;
      box-shadow: 0 -2px $pds-color-neutral-100;
      cursor: pointer;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    color: $pds-grey-fg-light;
    font-size: $spacing-m;

    &__month {
      color: $pds-grey-fg-light;

      &.active {
        color: $pds-color-neutral-800;
      }
    }

    &__day {
      color: $pds-color-neutral-800;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    padding-left: $spacing-l;
  }

  &__values {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    margin-left: auto;

    button {
      align-self: end;
      justify-content: flex-end;
      margin-top: auto;
    }
  }

  &__amount {
    .positive {
      color: $pds-color-green-700;
    }

    &__sign {
      color: $pds-color-red-600;
    }
  }

  &__rolling-balance {
    color: $pds-grey-fg-light;

    &.active {
      color: $pds-color-neutral-800;
    }
  }

  &-container {
    padding-right: $spacing-s;
    padding-left: $spacing-s;

    &:not(:first-child, :last-child) {
      .payment-item {
        border-bottom: $spacing-xxs solid $pds-color-neutral-100;
      }
    }

    &.expanded {
      box-shadow: inset $spacing-xs 0 $pds-brand-cta;
    }
  }

  &__description {
    color: $pds-grey-fg-light;

    &.active {
      margin-bottom: $spacing-m;
      color: $pds-color-neutral-800;
    }
  }
}
