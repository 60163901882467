@import '../../../../styles/reference';

.Modal {
  padding: $spacing-xl;

  &Head {
    &Container {
      margin-bottom: $spacing-l;
    }

    &Title {
      margin-bottom: $spacing-s;
      padding-top: $spacing-m;
      font-weight: 700;
      font-size: rem-calc(24);
      line-height: rem-calc(30);
    }
  }

  &Body {
    &Container {
      margin-bottom: $spacing-l;
      padding: $spacing-m;
      background-color: $gray-100;
    }

    &List {
      margin-bottom: 0;
      list-style: none;
      padding-inline-start: 0;

      &Item {
        display: flex;
        justify-content: space-between;
        padding-bottom: $spacing-m;
        line-height: rem-calc(24);

        &Label {
          font-weight: 600;
        }
      }
    }

    &Footer {
      margin-bottom: 0;
      color: $pds-grey-fg-light;
      font-size: rem-calc(14);
      line-height: rem-calc(20);
    }
  }
}
