@import '../../../styles/reference';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: rem-calc(40px);
}

#qualtrics-form-main-footer {
  width: 100%;
}

.mobile-banner {
  &.card {
    margin-bottom: 0;
    padding: rem-calc(16px) rem-calc(8px);
    border: 0;
    border-radius: 0;
  }

  position: relative;
  display: flex;
  align-items: center;
  height: rem-calc(64px);
  margin-bottom: 0;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  box-shadow: 0 0 15px rgba($pds-color-neutral-400, 0.3);

  &__close-btn {
    cursor: pointer;

    svg {
      path:first-of-type {
        stroke: $pds-color-neutral-500;
      }
    }
  }

  &__info-icon {
    svg {
      width: rem-calc(32px);
      height: rem-calc(32px);
      margin: 0 rem-calc(13px);
    }
  }

  &__body {
    display: flex;
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: rem-calc(12px);

    & &-heading {
      font-weight: $bolder-text;
    }
  }

  &__btn {
    &.ba-button.small {
      min-width: rem-calc(48px);
      height: rem-calc(24px);
      padding: rem-calc(4px) rem-calc(8px);
      font-weight: $bolder-text;
      font-size: rem-calc(12px);
    }

    margin-left: auto;
  }
}

.layout {
  display: flex;
  align-items: stretch;
  height: 100%;
  overflow: hidden;

  @media (max-width: $breakpoint-m) {
    flex-direction: column;
  }
}

.content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  // this css will change when left nav is implemented
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 $spacing-m $spacing-l;
  overflow-y: visible;

  @media (min-width: $breakpoint-s) {
    max-width: $container-max-size;
  }

  @media (min-width: $breakpoint-l) {
    padding-bottom: $spacing-l;
  }

  &-scroll {
    height: 100%;
    overflow: auto;
  }
}
