@import '../../../styles/reference';

.download-buttons {
  display: flex;
  align-items: flex-end;

  &__label {
    margin-bottom: 0;
    font-weight: normal;

    &.pds-text {
      line-height: rem-calc(16px);

      @media (min-width: $breakpoint-s) {
        line-height: rem-calc(24px);
      }
    }
  }

  &__icon {
    width: rem-calc(20px);
    height: rem-calc(20px);
    margin-left: $spacing-s;

    @media (min-width: $breakpoint-s) {
      width: rem-calc(28px);
      height: rem-calc(28px);
    }

    svg {
      path {
        fill: $pds-color-neutral-900;
      }
    }
  }

  .button-spinner {
    margin-left: $spacing-xl;
  }

  &:focus {
    &#{&} {
      outline: none;
      box-shadow: none;
    }
  }
}

.option {
  &__label {
    margin-bottom: 0;
  }

  &__spinner {
    &#{&} {
      width: rem-calc(24px);
      height: rem-calc(24px);
      margin-left: auto;
    }
  }
}
