@import '../../../styles/reference';

.Connection {
  &Status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(20px);
    margin-bottom: $spacing-xs;
    font-size: rem-calc(12px);
    border-radius: $spacing-xs;
  }

  &Connected {
    width: rem-calc(72px);
    color: $pds-color-green-700;
    background-color: $pds-color-green-100;
  }

  &Unconnected {
    width: rem-calc(94px);
    color: $pds-grey-fg;
    background-color: $pds-color-neutral-100;
  }

  &Error {
    width: rem-calc(68px);
    color: $pds-color-red-700;
    background-color: $pds-color-red-50;
  }
}
