@import '../../../../styles/reference';

.account-selector-option {
  padding: $spacing-m;
  cursor: pointer;

  &:focus {
    border: 2px solid $pds-color-black;
    outline: 0;
  }

  @media (min-width: ($breakpoint-s)) {
    padding: $spacing-l;
  }

  &__selected-option {
    align-items: center;
    width: 100%;
    margin-right: $spacing-m;

    @media (min-width: ($breakpoint-s)) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__col {
    p {
      margin-bottom: 0;
    }

    @media (min-width: ($breakpoint-s)) {
      display: flex;
      flex-direction: column;
    }
  }

  &__text {
    font-size: rem-calc(12px);

    @media (min-width: ($breakpoint-s)) {
      font-size: $font-size-base;
    }
  }

  &__status-badge {
    display: flex;
    justify-content: space-between;

    @media (min-width: $breakpoint-s) {
      justify-content: flex-start;
    }

    p {
      margin-bottom: 0;
      font-weight: $bolder-text;
    }

    span {
      display: flex;
      align-items: center;
      height: max-content;
      margin-top: rem-calc(1px);
      margin-left: $spacing-s;
    }
  }

  &__total {
    font-weight: $bolder-text;
    font-size: rem-calc(12px);

    &-right-pad {
      padding-right: $spacing-xs;
    }

    @media (min-width: ($breakpoint-s)) {
      font-size: $font-size-base;

      &-right-pad {
        padding-right: 0;
      }
    }
  }

  &__available {
    color: $pds-grey-fg-light;
  }

  &__closed {
    color: $pds-color-neutral-800;
    font-weight: $bolder-text;
  }

  &__col:last-child {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: rem-calc(12px);

    @media (min-width: ($breakpoint-s)) {
      flex-direction: column;
      align-items: flex-end;
      font-size: $font-size-base;
    }
  }

  .col-details {
    @media (max-width: ($breakpoint-s)) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &:hover {
    background-color: $pds-color-neutral-100;
  }

  &:not(:last-child, :focus) {
    border-bottom: $spacing-xxs solid $pds-color-neutral-100;
  }

  &.disabled {
    color: $pds-grey-fg-lighter;
    background-color: $pds-grey-bg-light;
    pointer-events: none;
  }
}
