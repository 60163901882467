@import '../../../../styles/reference';

.activate-card {
  &__card-number-form {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-l;

    .react-code-input {
      /* react-code-input doesn't allow overriding the inline style */
      display: flex !important;
      justify-content: space-between;
      max-width: rem-calc(284px);

      /* https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */

      /* disable up down arrow keys for input */

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }

      /* Firefox */
      input[type='number'] {
        appearance: textfield;
      }

      input {
        margin-bottom: $spacing-xl;
        border: 2px solid $pds-color-neutral-200;
        outline: none;

        &:focus-visible {
          border-bottom: 2px solid $pds-brand-cta;
        }
      }

      :last-child {
        margin-right: 0;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: $spacing-l;
    }
  }

  &__error {
    color: $pds-color-red-600;
  }
}

svg {
  max-width: rem-calc(270px);
  max-height: rem-calc(170px);
}
