/* stylelint-disable selector-class-pattern */
@import '../../../../styles/reference';

.MobileDownloadIconIos {
  width: rem-calc(119px);
  padding-bottom: $spacing-s;
  cursor: pointer;
}

.MobileDownloadIconAndroid {
  width: rem-calc(136px);
  padding-bottom: $spacing-s;
  cursor: pointer;
}

.MobileDownloadIconCode {
  width: rem-calc(66px);
  background-color: $pds-brand-grey-light;
  border-radius: rem-calc(8px);
  cursor: pointer;
  @media (min-width: ($breakpoint-s)) {
    width: rem-calc(86px);
  }
}

:global {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :local(.MobileAppsBanner) {
    &[class^='Card'] {
      position: relative;
      padding: $spacing-s;
      @media (min-width: ($breakpoint-s)) {
        height: rem-calc(118px);
      }

      & .banner__info {
        align-content: center;
      }

      & .banner__right-info-icon {
        width: rem-calc(86px);
        margin: 0;
        padding-right: $spacing-l;
        @media (min-width: ($breakpoint-s)) {
          margin-right: $spacing-m;
          margin-left: auto;
        }
      }

      & .banner__info-icon {
        order: 3;
        width: rem-calc(140px);
        height: rem-calc(118px);
        @media (min-width: ($breakpoint-s)) {
          order: 1;
          width: rem-calc(169px);
        }

        & img {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 115%;
          @media (min-width: ($breakpoint-s)) {
            height: 130%;
            margin: rem-calc(5px) 0 0 rem-calc(25px);
          }
        }
      }

      & .banner__body {
        order: 2;
        max-width: rem-calc(161px);
        margin: $spacing-s $spacing-xxs 0 0;
        line-height: rem-calc(16px);

        @media (min-width: ($breakpoint-xs)) {
          margin: $spacing-s $spacing-m 0 0;
        }

        @media (min-width: ($breakpoint-s)) {
          order: 2;
          max-width: 100%;
          margin: 0 0 0 rem-calc(66px);
        }
      }

      & .banner__info-arrival {
        margin-right: 0;
        font-size: rem-calc(12px);
        @media (min-width: ($breakpoint-s)) {
          margin-right: $spacing-xs;
          font-size: rem-calc(14px);
        }
      }

      & .banner__close-btn {
        align-self: start;
        order: 1;

        @media (min-width: ($breakpoint-s)) {
          order: 3;
        }
      }
    }
  }
}
