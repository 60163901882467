@import '../../../../styles/reference';

.ArrearsAlert {
  @media (min-width: $breakpoint-s) {
    align-items: flex-start;
  }

  &.Page {
    margin-bottom: $spacing-m;
    @media (min-width: $breakpoint-s) {
      margin-bottom: $spacing-xl;
    }
  }

  &.List {
    width: 100%;
    margin-top: $spacing-m;
  }

  &Body {
    flex-grow: 0;
    width: 75%;
    @media (min-width: $breakpoint-s) {
      width: max-content;
    }
  }

  &FullBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &Description {
    line-height: $spacing-l;
  }

  &CTA {
    line-height: $spacing-l;
  }
}
