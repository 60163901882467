@import '../../../../styles/reference';

.input {
  &-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
    }

    .tooltip {
      margin-left: $spacing-xs;
      cursor: pointer;
    }

    &:focus-within label,
    .filled {
      font-size: rem-calc(12px);
      line-height: rem-calc(16px);
      transform: translate(0, 0);

      @media (min-width: $breakpoint-s) {
        font-size: rem-calc(14px);
        line-height: rem-calc(20px);
      }
    }

    .error {
      border-bottom: 2px solid $pds-color-red-600;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    label {
      position: absolute;
      color: $pds-grey-fg-light;
      white-space: nowrap;
      transform: translate(0, $spacing-m) scale(1);
      transform-origin: top left;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      @media (min-width: $breakpoint-s) {
        transform: translate(0, $spacing-l) scale(1);
      }
    }

    input {
      height: rem-calc(44px);
      padding: $spacing-m 0 0 0;
      border: none;
      border-bottom: 2px solid $pds-color-neutral-100;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      @media (min-width: $breakpoint-s) {
        height: rem-calc(52px);
      }

      &:focus {
        &:not(.error) {
          border-bottom: 2px solid $pds-brand-cta;
        }
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }

      /* Firefox */
      &[type='number'] {
        appearance: textfield;
      }
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 200px;
  }

  &__error {
    margin-top: $spacing-xs;
    color: $pds-color-red-600;
  }
}
