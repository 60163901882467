@import '../../../../styles/reference';

.Section {
  &Container {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-s;

    @media (min-width: $breakpoint-s) {
      margin-bottom: $spacing-s;
    }

    &Swapped {
      flex-direction: column-reverse;
    }
  }

  &InnerBody {
    display: flex;
    flex-direction: column;

    & > div {
      margin-bottom: 0;
    }

    &Bottom {
      & > div:last-child {
        margin-bottom: 0;
      }
    }
  }
}
