@import '../../../styles/reference';

.RepaymentCalculator {
  &Heading {
    @media screen and (min-width: $breakpoint-m) {
      margin-top: 0;
    }

    margin: $spacing-l 0;
  }

  &HeadingInfo {
    margin-bottom: $spacing-xl;
    font-weight: 400;
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }

  &Disclaimer {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
  }

  &ButtonSection {
    display: flex;
    justify-content: flex-end;
    padding-top: rem-calc(20px);
    border-top: $curve-2 solid $pds-color-neutral-100;

    &Caption {
      display: flex;
      align-items: center;
      margin: rem-calc(12px);
    }
  }
}
