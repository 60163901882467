@import '../../../../styles/reference';

.ErrorModal {
  display: flex;
  flex-direction: column;

  &Heading {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }

  &Body {
    margin-bottom: $spacing-m;
    font-size: rem-calc(14);
    line-height: rem-calc(20);
  }
}
