@import '../../../../styles/reference';

.security-code {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ($breakpoint-s - 1)) {
    .react-code-input {
      max-width: rem-calc(440px);

      input {
        width: rem-calc(48px);
        height: rem-calc(48px);
      }
    }
  }

  .phone-number-text {
    font-weight: $bolder-text;
  }

  &__footer {
    .ba-button {
      display: inline-flex;
      color: $pds-color-neutral-800;
      font-weight: 500;
      text-decoration: underline;

      &.link:hover {
        text-decoration: none;
      }
    }

    &-resent {
      margin-left: $spacing-s;
    }
  }

  &__header {
    margin-top: $spacing-l;
  }

  &__text {
    margin-top: $spacing-m;
  }
}
