@import '../../../../../styles/reference';

.MissedRepaymentModal {
  &Bold {
    font-weight: $bolder-text;
  }

  &Heading {
    margin-top: $spacing-l;
    margin-bottom: $spacing-m;
  }

  &Card {
    &Collection {
      padding: $spacing-m;
    }

    background-color: $pds-color-neutral-50;

    &Divider {
      margin-top: 0;
      margin-bottom: $spacing-s;
      border-top: 2px solid $pds-color-neutral-100;
      opacity: 1;
    }
  }

  &MissedPaymentDetailBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &Row {
      display: flex;

      p {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: $spacing-m;
      }

      &Heading {
        width: 38%;
        min-width: rem-calc(190px);
      }

      &HeadingValue {
        width: 100%;
        text-align: right;
      }
    }

    &Item {
      color: $pds-grey-fg-light;
    }
  }
}
