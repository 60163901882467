@import '../../styles/reference';

%share-width {
  width: 100%;
  @media (min-width: ($breakpoint-s)) {
    width: rem-calc(578px);
  }
}
%share-border-radius {
  border-radius: rem-calc(4px);
}

.DocumentViewContainer {
  @extend %share-width;
  @extend %share-border-radius;
}

.DocumentViewerImage {
  @extend %share-width;
  @extend %share-border-radius;
}
