@import '../../../styles/reference';

$width: rem-calc(120px);
$maxwidth: rem-calc(140px);

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }

  11%,
  89% {
    opacity: 1;
  }
}

.toasts {
  display: block;

  &__toast {
    display: flex;
    width: fit-content;
    min-width: $width;
    height: rem-calc(36px);
    margin: auto;
    margin-bottom: $spacing-m;
    padding: $spacing-s $spacing-m;
    color: $pds-color-white;
    background: $pds-color-neutral-800;
    border-radius: $curve-4;
    box-shadow: 0 14px 28px rgba($pds-color-neutral-800, 0.3);
    animation: fadeinout 5s linear forwards;

    @media (min-width: $breakpoint-s) {
      min-width: $maxwidth;
      height: $spacing-xxxl;
      padding: rem-calc(12px) $spacing-m;
    }

    .toast-text {
      margin-bottom: 0;
    }

    .toast-icon {
      display: flex;
      align-items: center;
      width: rem-calc(20px);
      margin-right: $spacing-s;

      &__default {
        path {
          fill: $pds-color-white;
        }
      }

      svg {
        width: rem-calc(20px);
        height: rem-calc(20px);

        path {
          fill: $pds-color-white;
          stroke: $pds-color-white;
        }
      }
    }
  }

  &-container {
    position: absolute;
    right: 0;
    bottom: $spacing-m;
    left: 0;
    z-index: 101;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    @media (min-width: $breakpoint-s) {
      bottom: $spacing-xxxl;
    }
  }
}
