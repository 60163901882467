@import '../../../styles/reference';

.RepaymentProgress {
  margin-bottom: $spacing-m;

  @media (min-width: $breakpoint-s) {
    margin-bottom: $spacing-l;
  }

  .LabelContainer {
    display: flex;
    justify-content: space-between;

    .LabelTitle {
      margin-bottom: 0;
      color: $pds-color-neutral-500;
      font-weight: 400;
      line-height: rem-calc(24px);
    }

    .LabelValue {
      margin-bottom: $spacing-m;
      color: $pds-color-neutral-800;
      font-weight: 700;
      font-size: rem-calc(20px);
      line-height: rem-calc(28px);

      @media (min-width: $breakpoint-s) {
        font-size: rem-calc(24px);
        line-height: rem-calc(30px);
      }
    }

    &RightSide {
      text-align: right;
    }
  }

  .ProgressBar {
    max-width: 100%;
    height: rem-calc(8px);
    background-color: $pds-color-neutral-200;
    border-radius: rem-calc(8px);

    div {
      border-radius: rem-calc(8px);
    }
  }
}
