@import '../../../styles/reference';

.Container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  @media (min-width: $breakpoint-s) {
    flex-direction: row;
    align-items: center;
  }

  .RightSection {
    display: flex;
    width: 100%;
    margin-top: $spacing-s;
    @media (min-width: $breakpoint-s) {
      justify-content: flex-end;
      width: 50%;
      margin-top: 0;
    }

    .CopyText {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      padding: rem-calc(12px) $spacing-m;
      cursor: default;

      @media (min-width: $breakpoint-s) {
        width: inherit;
      }

      &:not(:last-of-type) {
        margin-right: $spacing-s;
      }
    }
  }
}

.LeftSection {
  display: flex;
  align-items: center;
  height: 100%;

  p {
    margin: 0;
  }
}

.ContainerWithBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Icon {
  display: none;
  margin-right: $spacing-s;
  @media (min-width: $breakpoint-s) {
    display: block;
  }
}
