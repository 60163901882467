@import '../../styles/reference';

.DropdownToggle {
  display: flex;
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  line-height: inherit;
  background: none;
  border: none;
}

div.DropdownMenu {
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-link-color: #{$pds-color-neutral-800};
  --bs-dropdown-link-hover-color: #{$pds-color-neutral-800};
  --bs-dropdown-link-hover-bg: #{$pds-grey-bg-light};
  --bs-dropdown-link-active-color: #{$pds-color-neutral-800};
  --bs-dropdown-link-active-bg: #{$pds-grey-bg-light};
  --bs-dropdown-border-color: #{$pds-grey-bg};

  // TODO: no colour variable for the shadow
  box-shadow: 0 7px 24px 0 rgb(2 18 13 / 30%);
}

.DropdownMenuItem {
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.75rem;

  border-bottom: 1px solid $pds-grey-bg;

  &:last-child {
    border-bottom: 0;
  }
}
