@import '../../../../styles/reference';

.transactions-loader {
  padding: rem-calc(20px) $spacing-m;
  background-color: $pds-color-white;

  @media (min-width: $breakpoint-s) {
    padding: rem-calc(28px) $spacing-l;
  }

  .item {
    margin-bottom: rem-calc(20px);
    border-bottom: 2px solid $pds-color-neutral-100;

    @media (min-width: $breakpoint-s) {
      margin-bottom: rem-calc(28px);
    }
  }

  .title {
    width: $spacing-5xl;
    height: rem-calc(12px);
    margin-bottom: rem-calc(20px);

    @media (min-width: $breakpoint-s) {
      height: $spacing-m;
      margin-bottom: $spacing-xl;
    }
  }

  .top-line {
    display: flex;
    height: $spacing-m;
    margin-bottom: $spacing-s;

    @media (min-width: $breakpoint-s) {
      height: rem-calc(20px);
    }

    &__date {
      width: rem-calc(20px);
      margin-right: $spacing-m;

      @media (min-width: $breakpoint-s) {
        width: $spacing-l;
        margin-right: $spacing-l;
      }
    }

    &__desc {
      width: rem-calc(152px);

      @media (min-width: $breakpoint-s) {
        width: rem-calc(226px);
      }
    }

    &__amount {
      width: rem-calc(68px);
      margin-left: auto;

      @media (min-width: $breakpoint-s) {
        width: rem-calc(94px);
      }
    }
  }

  .bottom-line {
    display: flex;
    height: rem-calc(12px);
    margin-bottom: rem-calc(20px);

    @media (min-width: $breakpoint-s) {
      height: $spacing-m;
      margin-bottom: rem-calc(28px);
    }

    &__date {
      width: rem-calc(20px);
      margin-right: $spacing-m;

      @media (min-width: $breakpoint-s) {
        width: $spacing-l;
        margin-right: $spacing-l;
      }
    }

    &__desc {
      width: rem-calc(119px);

      @media (min-width: $breakpoint-s) {
        width: rem-calc(138px);
      }
    }

    &__amount {
      margin-left: auto;

      @media (min-width: $breakpoint-s) {
        width: rem-calc(155px);
      }
    }
  }
}
