@import '../../../../styles/reference';

@mixin datepicker-base-day {
  min-width: rem-calc(35px);
  margin: rem-calc(3px) 0;
  color: $pds-grey-fg-light;
  font-size: rem-calc(14px);
  line-height: rem-calc(24px);
}

@mixin datepicker-input {
  color: $pds-color-neutral-800;
  background-color: $pds-brand-cta;
  border-radius: 0;
}

@mixin datepicker-select {
  color: $pds-color-neutral-800;
  font-weight: $bolder-text;
  background: $pds-brand-cta;
  outline-color: $pds-brand-cta;
}

.datepicker {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: rem-calc(44px);
  margin-bottom: $spacing-l;
  background-color: $pds-color-white;

  @media (min-width: $breakpoint-s) {
    height: rem-calc(52px);
    margin-bottom: 0;
  }

  &__mobile {
    display: flex;
    align-items: center;

    &-input {
      border-radius: 0;
      appearance: button;

      &::-webkit-calendar-picker-indicator {
        background: transparent;
      }

      &::-webkit-date-and-time-value {
        text-align: left;
      }

      &:focus::before {
        content: '';
      }

      &:not(:focus, .has-value)::before {
        margin-right: 0.5em;
        color: lightgrey;
        content: attr(placeholder);
      }

      &:in-range:not(.has-value)::-webkit-datetime-edit-year-field,
      &:in-range:not(.has-value)::-webkit-datetime-edit-month-field,
      &:in-range:not(.has-value)::-webkit-datetime-edit-day-field,
      &:in-range:not(.has-value)::-webkit-datetime-edit-text {
        display: none;
      }
    }

    &-icon {
      position: absolute;
      right: 0;
      width: rem-calc(20px);
      height: rem-calc(20px);
      margin-right: $spacing-s;
      padding-bottom: $spacing-xs;
      pointer-events: none;
    }
  }

  input {
    width: 100%;
    padding: 0 0 $spacing-xs;
    color: $pds-color-neutral-800;
    background-color: $pds-color-white;
    border: none;
    border-bottom: 2px solid $pds-color-neutral-100;

    &:focus {
      border-bottom: 2px solid $pds-brand-cta;
      outline: none;
    }

    &:hover {
      border-bottom: 2px solid $pds-brand-cta;
    }

    /* Chrome/Opera/Safari */
    &::input-placeholder {
      color: $pds-grey-fg-light;
    }

    /* Firefox 19+ */
    &::placeholder {
      color: $pds-grey-fg-light;
    }

    /* IE 10+ */
    &:input-placeholder {
      color: $pds-grey-fg-light;
    }

    /* Firefox 18- */
    &:placeholder {
      color: $pds-grey-fg-light;
    }
  }

  &__label {
    width: 100%;
    margin-bottom: rem-calc(2px);
    color: $pds-grey-fg-light;
  }

  &__error {
    width: 100%;
    margin-top: $spacing-xs;
    color: $pds-color-red-600;
  }

  &.error {
    input {
      border-bottom: 2px solid $pds-color-red-600;
    }
  }

  .react-datepicker {
    width: 100%;
    color: $pds-color-neutral-800;
    border: none;
    box-shadow: 0 7px 24px -5px rgba($pds-color-black, 0.3);

    &__month {
      margin: 0 rem-calc(5px);
    }

    &__header {
      margin: 0 rem-calc(5px);
      padding-top: 0;
      font-weight: 500;
      font-size: rem-calc(14px);
      background: none;
      border: none;
    }

    &__current-month {
      padding: rem-calc(10px) 0;
      color: $pds-color-neutral-800;
      font-size: rem-calc(16px);
      border-bottom: 1px solid $pds-color-neutral-100;
    }

    &__day {
      @include datepicker-base-day;

      &--disabled {
        color: $pds-grey-fg-lighter;
      }

      &--today {
        font-weight: 400;
      }

      &--outside-month {
        visibility: hidden;
        opacity: 0;
      }

      &-name {
        @include datepicker-base-day;

        color: $pds-color-neutral-800;
        font-weight: $bolder-text;
      }

      &--selected,
      &--range-start,
      &--range-end {
        color: $pds-color-neutral-800;
        font-weight: $bolder-text;
        background-color: $pds-brand-cta;
      }

      &--selected {
        &:hover {
          border-radius: 5px;
        }

        &:focus {
          outline: none;
        }
      }

      &--range-start,
      &--selecting-range-start {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &--range-end,
      &--selecting-range-end {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &--in-selecting-range {
        @include datepicker-input;
      }

      &--in-range {
        @include datepicker-input;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      &--keyboard-selected {
        @include datepicker-select;
      }
    }

    &__navigation--next {
      width: rem-calc(25px);
      height: rem-calc(25px);
      margin-top: 7px;
      background: url('~@prospa/icons/dist/svg/ChevronRight.svg') no-repeat center;
      border: none;
    }

    &__navigation-icon--next {
      display: none;
    }

    &__navigation-icon--previous {
      display: none;
    }

    &-wrapper {
      display: flex;
      width: 100%;
    }

    &__triangle {
      display: none;
    }

    &__navigation--previous {
      width: rem-calc(25px);
      height: rem-calc(25px);
      margin-top: 7px;
      background: url('~@prospa/icons/dist/svg/ChevronRight.svg') no-repeat center;
      border: none;
      transform: rotate(180deg);
    }

    &__month,
    &__quarter {
      &--text {
        &--selected,
        &--in-selecting-range,
        &--in-range {
          @include datepicker-input;
        }

        &--keyboard-selected {
          @include datepicker-select;
        }
      }
    }
  }
}
