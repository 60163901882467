@import '../../styles/reference';

.ba-button {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  font-weight: $bolder-text;
  border: none;
  border-radius: $spacing-xs;
  transition: all 0.15s ease-in-out;

  svg {
    margin-right: $spacing-s;
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.5;
  }

  .button-spinner {
    position: absolute;
    opacity: 0;

    &.loading {
      opacity: 1;
    }
  }

  &.extra-small {
    height: rem-calc(34px);
    padding: rem-calc(7px) rem-calc(11px);
    font-size: rem-calc(14px);

    .button-spinner {
      width: $spacing-s;
      height: $spacing-s;
    }
  }

  &.small {
    min-width: $spacing-5xl;
    height: rem-calc(34px);
    padding: rem-calc(10px) $spacing-m;
    font-size: rem-calc(14px);

    .button-spinner {
      width: $spacing-m;
      height: $spacing-m;
    }
  }

  &.medium {
    min-width: rem-calc(100px);
    height: rem-calc(48px);
    padding: $spacing-m $spacing-l;
    font-weight: $bolder-text;
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);

    @media (min-width: $breakpoint-s) {
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
    }

    .button-spinner {
      width: rem-calc(20px);
      height: rem-calc(20px);
    }
  }

  &.large {
    min-width: rem-calc(120px);
    height: rem-calc(56px);
    padding: $spacing-m $spacing-l;
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);

    @media (min-width: $breakpoint-s) {
      font-size: rem-calc(20px);
      line-height: rem-calc(28px);
    }

    .button-spinner {
      width: rem-calc(28px);
      height: rem-calc(28px);
    }
  }

  &.primary {
    color: $pds-color-neutral-800;
    background: $pds-brand-cta;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($pds-color-green-300, 0.4);
    }

    &:disabled,
    &.disabled {
      color: white;
      background-color: $pds-color-neutral-400;
    }

    &:not(.disabled):hover {
      background:
        linear-gradient(0deg, rgba($pds-color-neutral-800, 0.1), rgba($pds-color-neutral-800, 0.1)),
        $pds-brand-cta;
      background-blend-mode: multiply, normal;
      box-shadow: 0 $spacing-s $spacing-m rgba($pds-color-green-200, 0.4);
      transform: translateY(rem-calc(-2px));
    }

    &.loading {
      background: $pds-brand-cta;
    }
  }

  &.secondary {
    color: $pds-color-neutral-800;
    background: $pds-color-white;
    box-shadow: 0 0 rem-calc(15px) rgba($pds-color-neutral-400, 0.3);

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($pds-brand-secondary, 0.47);
    }

    &:not(.disabled):hover {
      color: $pds-color-white;
      background: $pds-color-neutral-900;
      box-shadow: 0 $spacing-s $spacing-m rgba($pds-color-white, 0.47);
      transform: translateY(rem-calc(-2px));
    }

    &.loading {
      background: $pds-color-white;
    }
  }

  &.secondary-outline {
    color: $pds-color-neutral-800;
    background: $pds-color-white;
    border: 2px solid $pds-color-neutral-100;
    box-shadow: none;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &:not(.disabled):hover {
      color: $pds-color-white;
      background: $pds-color-neutral-900;
      border: 2px solid $pds-color-neutral-900;
      transform: translateY(rem-calc(-2px));
    }

    &.loading {
      background: $pds-color-white;
    }
  }

  &.tertiary {
    color: $pds-color-green-700;
    background: $pds-color-white;
    box-shadow: inset 0 0 0 $spacing-xxs $pds-color-neutral-100;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($pds-color-green-700, 0.4745);
    }

    &:not(.disabled):hover {
      color: $pds-color-white;
      background:
        linear-gradient(0deg, rgba($pds-color-neutral-800, 0.1), rgba($pds-color-neutral-800, 0.1)),
        $pds-color-green-700;
      background-blend-mode: multiply, normal;
      box-shadow: 0 $spacing-s $spacing-m rgba($pds-color-green-200, 0.4);
      transform: translateY(rem-calc(-2px));
    }

    &.loading {
      background:
        linear-gradient(0deg, rgba($pds-color-neutral-800, 0.1), rgba($pds-color-neutral-800, 0.1)),
        $pds-color-green-700;
    }
  }

  &%link {
    min-width: auto;
    height: auto;
    padding: 0;
    color: $pds-color-green-700;
    background: none;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($pds-color-neutral-900, 0.25);
    }
  }

  &%linkhover {
    &:not(.disabled):hover {
      text-decoration: underline;
    }
  }

  &.link {
    @extend %link;
    @extend %linkhover;
  }

  &.iconlink {
    @extend %link;
    @extend %linkhover;

    color: $pds-color-neutral-800;
  }

  &.iconlinkunderline {
    @extend %link;

    color: $pds-color-neutral-800;
    font-weight: 500;
    text-decoration: underline;

    &:active {
      color: $pds-color-neutral-600;
    }

    &:not(.disabled):hover {
      text-decoration: none;
    }
  }

  /* loading states */
  &.loading svg {
    opacity: 0;
  }

  &.secondary:not(.disabled):hover svg {
    path {
      fill: $pds-color-white;
      stroke: $pds-color-white;
    }
  }

  .loading {
    opacity: 0;
  }

  // reset focus effect
  &.nofocus {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
