@import '../../../styles/reference';

.LoanDetails {
  margin-bottom: $spacing-l;

  @media (min-width: $breakpoint-s) {
    margin-bottom: $spacing-xl;
  }

  &Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    border-bottom: 1px solid $pds-color-neutral-800;
  }

  &Title {
    margin-bottom: 0;
    color: $pds-color-neutral-800;
  }

  &DropdownIcon {
    width: rem-calc(16px);
    height: rem-calc(16px);
    margin-left: $spacing-xs;

    &[data-open='false'] {
      transform: rotate(180deg);
      transition: all 0.2s ease;
    }

    path {
      fill: $pds-color-neutral-800;
    }
  }

  &Container {
    margin-bottom: $spacing-m;
    padding: $spacing-l 0;
    border-bottom: 2px solid $pds-color-neutral-200;

    @media (min-width: $breakpoint-s) {
      margin-bottom: $spacing-l;
      padding: $spacing-xl 0 $spacing-l 0;
    }

    div:last-child {
      margin-bottom: 0;
    }
  }

  &Set {
    margin-bottom: $spacing-l;

    @media (min-width: $breakpoint-s) {
      margin-bottom: $spacing-xl;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &Row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $spacing-m;
  }

  &FlexCol {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $breakpoint-s) {
      width: 66.7%;

      span {
        min-width: 50%;
      }
    }
  }

  &Col {
    width: 100%;

    @media (min-width: $breakpoint-s) {
      width: 33.3%;
    }
  }

  &SubSet {
    width: 100%;
    padding-top: $spacing-m;

    &Title {
      padding-left: 0;
      @media (min-width: $breakpoint-s) {
        padding-left: rem-calc(29px);
      }
    }

    .LoanDetailsFlexCol {
      span {
        font-weight: 400;
      }

      span:first-of-type {
        padding-left: rem-calc(10px);
        @media (min-width: $breakpoint-s) {
          padding-left: rem-calc(29px);
        }
      }
    }

    .LoanDetailsCol {
      padding-left: rem-calc(10px);
      @media (min-width: $breakpoint-s) {
        padding-left: 0;
      }
    }

    .LoanDetailsRow {
      &:first-of-type {
        padding-top: $spacing-m;
      }
    }
  }

  &Comment {
    color: $pds-color-neutral-500;
    font-size: rem-calc(14px);
    line-height: rem-calc(20);
  }

  &Footer {
    margin: 0;
    color: $pds-grey-fg-light;
  }
}
