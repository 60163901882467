@import '../../../styles/reference';

.paymentconfirmed {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: rem-calc(30px);
    border-bottom: 2px solid $pds-color-neutral-100;

    svg {
      margin-right: $spacing-l;
    }

    @media (max-width: ($breakpoint-s - 1)) {
      svg {
        margin-right: 0;
      }
    }

    &-details {
      display: flex;
      flex-direction: column;
      margin: 0;

      .pds-lead {
        font-weight: $bolder-text;
      }

      .pds-text {
        margin-top: $spacing-s;
        color: $pds-color-neutral-800;
      }

      .pds-caption {
        color: $pds-color-neutral-800;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: $spacing-l 0;
    border-bottom: 2px solid $pds-color-neutral-100;

    &-row {
      display: flex;

      p {
        margin-bottom: 0;
        white-space: pre;
      }

      &:not(:last-child) {
        margin-bottom: $spacing-l;
      }

      &-heading {
        width: 20%;
        min-width: rem-calc(130px);
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding-top: $spacing-l;

    @media (max-width: ($breakpoint-s - 1)) {
      border-top: none;

      button {
        width: 100%;
      }
    }
  }
}
