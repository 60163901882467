@import '../../styles/reference';

.PhoneNumber {
  color: $pds-color-neutral-900;
  text-decoration: underline;
  text-underline-offset: $spacing-xxs;

  &:hover {
    color: $pds-color-neutral-900;
    text-decoration: none;
  }
}
