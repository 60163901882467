@import '../../../styles/reference';
@mixin freeze {
  color: $pds-color-neutral-500;

  svg {
    path {
      fill: $pds-color-neutral-500;
    }
  }
}

.Payee {
  &Container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background-color: $pds-color-white;
  }

  &Icon {
    display: none;
    margin-right: $spacing-s;

    &Freeze {
      @include freeze;
    }

    @media (min-width: ($breakpoint-s)) {
      display: flex;
      width: rem-calc(24px);
      margin-right: $spacing-m;
    }
  }

  &Details {
    display: flex;
    flex-direction: column;
    width: 100%;

    &Freeze {
      @include freeze;
    }

    &Description {
      color: $pds-grey-fg-light;

      &Header,
      &MaskedNumber {
        font-weight: $bolder-text;
      }

      &Number {
        margin-top: $spacing-s;
        @media (min-width: ($breakpoint-s)) {
          margin-top: 0;
        }
      }
    }
  }
}
