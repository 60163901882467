body {
  color: $pds-color-neutral-800;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  background-color: $pds-grey-bg-light;

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  /* override prospa bootstrap style */
  color: $pds-color-neutral-800;
}

h1,
.h1 {
  font-weight: 700;
  font-size: rem-calc(32px);
  line-height: rem-calc(40px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(40px);
    line-height: rem-calc(48px);
  }
}

h2,
.h2 {
  font-weight: 700;
  font-size: rem-calc(28px);
  line-height: rem-calc(32px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(34px);
    line-height: rem-calc(42px);
  }
}

h3,
.h3 {
  font-weight: 700;
  font-size: rem-calc(24px);
  line-height: rem-calc(30px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(28px);
    line-height: rem-calc(32px);
  }
}

h4,
.h4 {
  font-weight: 700;
  font-size: rem-calc(20px);
  line-height: rem-calc(28px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(24px);
    line-height: rem-calc(30px);
  }
}

.pds-lead-semibold {
  font-weight: 600;
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(20px);
    line-height: rem-calc(28px);
  }
}

.pds-lead {
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(20px);
    line-height: rem-calc(28px);
  }
}

.pds-text-semibold {
  font-weight: 600;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }
}

.pds-text {
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }
}

.pds-summary-text-semibold {
  font-weight: 600;
  font-size: rem-calc(12px);
  line-height: rem-calc(16px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }
}

.pds-summary-text {
  font-size: rem-calc(12px);
  line-height: rem-calc(16px);

  @media (min-width: ($breakpoint-s)) {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }
}

.pds-caption {
  font-size: rem-calc(12px);
  line-height: rem-calc(16px);

  @media (min-width: $breakpoint-s) {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
  }
}

.pds-link {
  min-width: auto;
  height: auto;
  padding: 0;
  color: $pds-color-neutral-800;
  text-decoration: underline;
  background: none;
  border-radius: $spacing-xs;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($pds-color-neutral-900, 0.25);
  }

  &:hover {
    color: $pds-color-neutral-800;
    text-decoration: none;
  }

  &:active {
    color: $pds-color-neutral-600;
  }
}

button,
.ba-button,
[role='button'] {
  user-select: none;
}

.hidden {
  display: none;
}
