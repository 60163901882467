@import '../../../styles/reference';
%item-alert-style {
  @at-root div#{&} {
    width: 100%;
    margin-top: $spacing-m;
  }
}

.AccountList {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-l;

  @media (min-width: ($breakpoint-s)) {
    margin-bottom: $spacing-xl;
  }
}

.AccountListItem {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-m;
  background-color: $pds-color-white;
  border: $curve-2 solid $pds-color-neutral-100;
  border-radius: $curve-4;

  &WithFooter {
    @at-root div#{&} {
      @media (min-width: ($breakpoint-s)) {
        padding-bottom: $spacing-m;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: $spacing-s;
  }

  &:hover {
    background: $pds-grey-bg;
  }

  @media (min-width: ($breakpoint-s)) {
    justify-content: flex-start;
    min-height: rem-calc(104px);
    padding: $spacing-l;
  }

  &Clickable {
    cursor: pointer;
  }

  &Column {
    float: left;

    &Right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      @media (min-width: ($breakpoint-s)) {
        width: 48%;
        text-align: right;
      }

      &Row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 0;
        line-height: rem-calc(24px);

        span {
          display: inline-block;
          font-size: rem-calc(14px);
          line-height: rem-calc(16px);

          @media (min-width: $breakpoint-s) {
            font-size: rem-calc(16px);
            line-height: rem-calc(20px);
          }
        }

        &:not(&:last-of-type) {
          padding-bottom: rem-calc(6px);
        }
      }
    }

    &Left {
      width: 100%;
      padding-bottom: $spacing-s;

      @media (min-width: ($breakpoint-s)) {
        width: 52%;
        padding-bottom: 0;
      }

      .AccountListItem {
        &SubTitle {
          color: $pds-color-neutral-500;
          font-weight: 400;
        }

        &TitleStatus {
          display: flex;
          justify-content: space-between;

          @media (min-width: $breakpoint-s) {
            justify-content: flex-start;
          }

          p {
            margin-bottom: 0;
            font-weight: 600;
          }

          span {
            display: flex;
            align-items: center;
            height: max-content;
            margin-top: rem-calc(3px);
            margin-left: $spacing-s;
          }

          &WithExternalLink {
            span {
              margin-right: $spacing-xl;

              @media (min-width: $breakpoint-s) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    &Icon {
      position: absolute;
      top: $spacing-m;
      right: $spacing-m;

      svg {
        path {
          fill: $pds-color-neutral-900;
        }
      }

      @media (min-width: ($breakpoint-s)) {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        flex-grow: 1;
        justify-content: end;
      }
    }
  }

  &Label {
    width: auto;
    color: $pds-color-neutral-500;

    @media (min-width: $breakpoint-s) {
      width: 55%;
      text-align: right;
    }
  }

  &Value {
    width: auto;

    &Bold {
      font-weight: 600;
    }
  }

  &MissedRepayment {
    @extend %item-alert-style;
  }

  &ConnectionErrorAlert {
    @extend %item-alert-style;
  }

  &FreezeAccountAlert {
    @extend %item-alert-style;
  }
}

// remove this section after loc migration
.AccountListItemWithExternalLink {
  .AccountListItem {
    &Column {
      &Right {
        width: 100%;

        @media (min-width: $breakpoint-s) {
          width: 42%;
        }
      }
    }

    &Label {
      width: 45%;
    }
  }
}

.Skeleton {
  display: block;
}

.SuspendedAlert {
  width: 100%;
  margin-top: $spacing-l;
}

.Divider {
  @at-root hr#{&} {
    margin-top: $spacing-l;
    margin-bottom: $spacing-m;
  }

  &WithAlert {
    @at-root hr#{&} {
      margin: $spacing-m 0;
    }
  }
}
