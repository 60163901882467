@import '../../../../styles/reference';

.account-loader {
  padding: $spacing-m;

  @media (min-width: ($breakpoint-s)) {
    padding: $spacing-l;
  }

  .balance {
    height: $spacing-l;
    margin-bottom: rem-calc(6px);

    @media (min-width: ($breakpoint-s)) {
      height: rem-calc(28px);
    }
  }

  .available {
    height: $spacing-m;

    @media (min-width: ($breakpoint-s)) {
      height: rem-calc(20px);
    }
  }
}

.account-loader-block {
  width: rem-calc(172px);

  @media (min-width: $breakpoint-s) {
    width: rem-calc(216px);
  }
}
