@import '../../styles/reference';
@import '@prospa/salt-tokens/css/styles.css';

.Email {
  color: var(--color-text-default);
  text-decoration: underline;
  text-underline-offset: $spacing-xxs;

  &:hover {
    color: var(--color-text-default);
    text-decoration: none;
  }
}
