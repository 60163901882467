@import '../../../styles/reference';

.react-modal {
  z-index: 101;
  display: flex;
  flex-direction: column;
  padding: $spacing-xxl;
  overflow: auto;
  background: $pds-color-white;
  border: 1px solid $pds-color-neutral-300;
  border-radius: $curve-4;
  outline: none;

  @media (max-width: ($breakpoint-s - 1)) {
    width: 100%;
    height: 100%;
    padding: $spacing-m;
    border-radius: 0;
  }

  &__overlay {
    position: fixed;
    inset: 0;

    /* refer to sidenav */
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($pds-color-black, 0.5);
    backdrop-filter: blur(5px);
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-l;

    &-icon {
      &.ba-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem-calc(28px);
        height: rem-calc(28px);
        margin-bottom: auto;
        margin-left: auto;
        border-radius: $curve-4;

        svg {
          margin-right: 0;
        }

        &:hover,
        &:active {
          background: $pds-color-neutral-100;
          cursor: pointer;

          path {
            fill: $pds-color-neutral-800;
          }
        }
      }
    }
  }

  &__body {
    display: flex;
    padding-bottom: $spacing-xxl;

    &.hide-footer-border {
      border-bottom: none;

      @media (min-width: $breakpoint-s) {
        padding-bottom: 0;
      }
    }

    @media (min-width: $breakpoint-s) {
      border-bottom: rem-calc(2px) solid $pds-color-neutral-100;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: $spacing-xxl;
    margin-bottom: 0;

    @media (max-width: ($breakpoint-s - 1)) {
      flex-direction: column-reverse;
      margin-top: auto;
    }

    &-buttons {
      display: flex;
      margin-left: auto;

      @media (max-width: ($breakpoint-s - 1)) {
        flex-direction: column-reverse;
        width: 100%;
        max-width: 100%;
        margin-bottom: $spacing-m;
      }

      button {
        &:last-child {
          @media (min-width: $breakpoint-s) {
            margin-left: $spacing-m;
          }
        }

        &:first-child {
          @media (max-width: ($breakpoint-s - 1)) {
            margin-top: $spacing-s;
          }
        }

        &.ba-button {
          width: 100%;
          font-weight: 600;
          font-size: rem-calc(16px);

          @media (min-width: ($breakpoint-s)) {
            width: max-content;
          }
        }

        &.secondary {
          border: 2px solid $pds-color-neutral-100;
          box-shadow: none;

          &:not(.disabled):hover {
            border: 2px solid $pds-color-neutral-900;
          }
        }

        &.tertiary {
          color: $pds-color-neutral-800;
        }
      }
    }
  }

  &.small {
    @media (min-width: $breakpoint-s) {
      width: rem-calc(500px);
      max-width: rem-calc(500px);
    }
  }

  &.medium {
    @media (min-width: $breakpoint-s) {
      width: rem-calc(600px);
      max-width: rem-calc(600px);
    }
  }

  &.large {
    @media (min-width: $breakpoint-s) {
      width: rem-calc(700px);
      max-width: rem-calc(700px);
    }
  }
}
