@import '../../../styles/reference';

.AppStoreRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;

  &Text {
    font-weight: $bolder-text;
    font-size: $spacing-m;
  }

  &Stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 $spacing-xs;
  }

  &Store {
    color: $pds-grey-fg-light;
  }
}
