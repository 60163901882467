@import '../../../styles/reference';

.tooltip-info {
  position: relative;

  &__trigger {
    display: inline-block;
    width: 100%;
  }

  &__bubble {
    position: absolute;
    z-index: 10;
    max-width: 160px;
    white-space: nowrap;

    &::after {
      position: absolute;
      content: '';
    }
  }

  &__text {
    padding: $spacing-s rem-calc(12px);
    color: $pds-color-white;
    font-weight: 400;
    text-align: center;
    background: $pds-color-neutral-800;
    border-radius: $curve-4;
  }

  &__top {
    bottom: 150%;
    left: 50%;
    padding-bottom: $spacing-s;
    transform: translateX(-50%);

    &::after {
      bottom: 0;
      left: 50%;
      border-top: $spacing-s solid $pds-color-neutral-800;
      border-right: $spacing-s solid transparent;
      border-left: $spacing-s solid transparent;
      transform: translateX(-50%);
    }
  }

  &__bottom {
    top: 150%;
    left: 50%;
    padding-top: $spacing-s;
    transform: translateX(-50%);

    &::after {
      top: 0;
      left: 50%;
      border-right: $spacing-s solid transparent;
      border-bottom: $spacing-s solid $pds-color-neutral-800;
      border-left: $spacing-s solid transparent;
      transform: translateX(-50%);
    }
  }

  &__left {
    top: 50%;
    right: 100%;
    padding-right: $spacing-s;
    transform: translateY(-50%);

    &::after {
      top: 50%;
      right: 0;
      border-top: $spacing-s solid transparent;
      border-bottom: $spacing-s solid transparent;
      border-left: $spacing-s solid $pds-color-neutral-800;
      transform: translateY(-50%);
    }
  }

  &__right {
    top: 50%;
    left: 100%;
    padding-left: $spacing-s;
    transform: translateY(-50%);

    &::after {
      top: 50%;
      left: 0;
      border-top: $spacing-s solid transparent;
      border-right: $spacing-s solid $pds-color-neutral-800;
      border-bottom: $spacing-s solid transparent;
      transform: translateY(-50%);
    }
  }
}
