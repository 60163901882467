@import '../../../styles/reference';

.account-selector {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $spacing-m;
  background-color: $pds-color-white;
  border: $spacing-xxs solid $pds-color-neutral-100;
  border-radius: $spacing-xs;

  @media (min-width: ($breakpoint-s)) {
    min-height: rem-calc(76px);
  }

  &__selected-option {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right: $spacing-m;

    @media (min-width: $breakpoint-s) {
      justify-content: space-between;
    }
  }

  &__account-selected {
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $breakpoint-s) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__select {
    margin-bottom: 0;
    color: $pds-grey-fg-light;
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin-bottom: 0;
      text-align: left;
    }
  }

  &__col:last-child {
    flex-direction: row;
    align-items: flex-start;
    margin-top: $spacing-s;

    p:last-child {
      margin-left: $spacing-s;
    }

    @media (min-width: $breakpoint-s) {
      flex-direction: column;
      align-items: flex-end;
      margin-top: 0;

      p {
        margin: 0;
        text-align: right;
      }
    }
  }

  &__dropdown-icon {
    margin-top: $spacing-xs;
    margin-left: auto;
    cursor: pointer;

    path {
      fill: $pds-color-neutral-900;
    }

    &[data-open='true'] {
      transform: rotate(180deg);
      transition: all 0.2s ease;
    }

    &-active {
      display: flex;
    }
  }

  &__icon {
    display: none;
    align-self: flex-start;
    margin-right: $spacing-s;

    @media (min-width: ($breakpoint-s)) {
      display: flex;
      flex-shrink: 0;
      margin-right: $spacing-m;
    }

    g:first-child {
      fill: none;
    }

    &-center {
      align-self: center;
    }
  }

  &__menu {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    max-height: rem-calc(374px);
    margin-top: $spacing-s;
    overflow: auto;
    background: $pds-color-white;
    border-radius: $curve-2;
    box-shadow: 0 7px 24px rgba($pds-color-black, 0.3);
    cursor: initial;
    animation: fadeinmenu 0.15s ease-in;
    padding-inline-start: 0;
  }

  &__container {
    position: relative;
    width: 100%;
    margin-bottom: $spacing-m;

    @media (min-width: ($breakpoint-s)) {
      margin-bottom: $spacing-l;
    }

    &:hover {
      cursor: pointer;
    }

    button:focus:not(.account-selector-disabled) {
      border: 2px solid $pds-color-black;
      outline: 0;
    }
  }

  &__dropdown-disabled {
    &:hover {
      cursor: initial;
    }
  }

  &__selected-info {
    display: flex;
    flex-direction: column;
    @media (max-width: $breakpoint-s) {
      &-details {
        display: none;
      }
    }

    &-name {
      padding-right: $spacing-s;
    }
  }

  &-disabled {
    color: $pds-color-neutral-500;

    &-svg {
      path {
        fill: $pds-color-neutral-500;
      }
    }
  }
}
