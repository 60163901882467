@import '../../../../styles/reference';

.checkbox {
  display: grid;
  grid-gap: $spacing-s;
  grid-template-columns: min-content auto;
  margin-bottom: $spacing-xs;

  &--disabled {
    color: var(--disabled);
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $spacing-m;
    height: $spacing-m;
    background: $pds-color-white;
    border-radius: $curve-2;
    box-shadow: 0 0 0 $curve-2 inset $pds-color-neutral-200;

    @media (min-width: $breakpoint-s) {
      width: $spacing-l;
      height: $spacing-l;
    }

    &.error {
      box-shadow: inset 0 0 0 2px $pds-color-red-600;
    }

    svg {
      opacity: 0;
      transition-duration: 0.1s;
    }
  }

  &__input {
    display: grid;
    grid-template-areas: 'checkbox';
    align-content: center;

    > * {
      grid-area: checkbox;
    }

    svg {
      path {
        fill: black;
      }
    }

    input {
      width: $spacing-m;
      height: $spacing-m;
      cursor: pointer;
      opacity: 0;

      @media (min-width: $breakpoint-s) {
        width: $spacing-l;
        height: $spacing-l;
      }

      &:focus + .checkbox {
        &__control {
          box-shadow: 0 0 0 $curve-2 inset $pds-color-neutral-400;
        }
      }

      &:hover + .checkbox {
        &__control {
          box-shadow: 0 0 0 $curve-2 inset $pds-color-neutral-400;
        }
      }

      &:checked + .checkbox {
        &__control {
          background: $pds-brand-cta;
          box-shadow: none;

          svg {
            opacity: 1;
          }
        }
      }

      &:disabled + .checkbox {
        &__control {
          color: var(--disabled);
        }
      }

      &[data-error='true'] + .checkbox {
        &__control {
          box-shadow: 0 0 0 $curve-2 inset $pds-color-red-600;
        }
      }
    }
  }

  &__label {
    display: grid;
    align-self: center;
    margin-right: auto;
    margin-bottom: 0;
    cursor: pointer;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__error {
    color: $pds-color-red-600;
  }

  &.disabled {
    opacity: 0.54;
    mix-blend-mode: normal;
    pointer-events: none;
  }
}
