@import '../../../../styles/reference';

%svg-position-reset {
  position: relative;
  top: 0;
  right: 0;
}

.IntegrationErrorAlert {
  &Auth {
    margin-bottom: $spacing-l;

    @media (max-width: $breakpoint-s) {
      margin-bottom: $spacing-m;
    }

    &Contents {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0;

      @media (max-width: $breakpoint-s) {
        flex-wrap: wrap;
        gap: $spacing-s;
        align-items: center;
      }

      & + div {
        @extend %svg-position-reset;
      }
    }
  }

  &Sync {
    width: 100%;
    cursor: default;

    &Contents {
      width: 100%;
      margin-bottom: 0;

      span {
        display: flex;

        @media (min-width: ($breakpoint-m - 1)) {
          display: inline;
        }

        button {
          display: block;
          margin-top: $spacing-xs;

          @media (min-width: ($breakpoint-m - 1)) {
            display: inherit;
            float: right;
            text-decoration: underline;
          }

          @media (min-width: ($breakpoint-s - 1)) {
            margin-top: 0;
          }
        }
      }

      & + div {
        @extend %svg-position-reset;
      }
    }
  }
}
