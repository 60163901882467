@import '../../../styles/reference';

.WeeklyRepayments {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-background-upsell-default);
  border: rem-calc(2px) solid $pds-grey-light;
  border-radius: rem-calc(4px);

  &Icon {
    @media (min-width: ($breakpoint-s)) {
      width: rem-calc(80px);
      height: rem-calc(80px);
    }

    justify-content: end;
    width: rem-calc(64px);
    height: rem-calc(64px);
    margin-top: auto;
  }

  h2 {
    margin: rem-calc(8px) 0;
  }
}
