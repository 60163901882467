@import '../../../../styles/reference';

.address-update-modal {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__icon {
    margin-bottom: $spacing-l;
  }

  &__subheading {
    margin-bottom: $spacing-l;
  }

  p {
    margin-bottom: $spacing-xl;
  }

  h4 {
    margin-bottom: $spacing-m;
  }

  .input-wrapper {
    margin-bottom: $spacing-l;
  }

  &__widget {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: rem-calc(144px);

    label {
      position: absolute;
      color: $pds-grey-fg-light;
      white-space: nowrap;
      transform: translate(24px, rem-calc(20px)) scale(1);
      transform-origin: top left;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      pointer-events: none;

      @media (min-width: $breakpoint-s) {
        transform: translate(24px, rem-calc(22px)) scale(1);
      }
    }

    &:focus-within label,
    .filled {
      font-size: rem-calc(12px);
      line-height: $spacing-m;
      transform: translate(0, 0);

      @media (min-width: $breakpoint-s) {
        font-size: rem-calc(14px);
        line-height: rem-calc(20px);
      }
    }

    input {
      height: rem-calc(44px);
      padding: $spacing-m 0 0 0;
      padding-left: $spacing-l;
      background: url('~@prospa/icons/dist/svg/WebSearch.svg') no-repeat scroll;
      background-position: left rem-calc(20px);
      background-size: rem-calc(20px);
      border: none;
      border-bottom: 2px solid $pds-color-neutral-100;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      @media (min-width: $breakpoint-s) {
        height: rem-calc(52px);
        background-position: left $spacing-l;
      }

      &:focus {
        &:not(.error) {
          border-bottom: 2px solid $pds-brand-cta;
        }
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .af_empty {
      padding: rem-calc(12px) $spacing-m;
    }
  }
}
