@import '../../../styles/reference';

.ImageOnlyBanner {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  &BackgroundImage {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  &DismissButton {
    position: absolute;
    top: rem-calc(16px);
    right: rem-calc(16px);

    @media (min-width: $breakpoint-s) {
      top: rem-calc(12px);
      right: rem-calc(12px);

      svg {
        width: rem-calc(20px);
        height: rem-calc(20px);
      }
    }
  }
}
