@import '../../../styles/reference';

.RepaymentsSchedule {
  &Table {
    margin: rem-calc(20px) 0;
  }

  &Header {
    border-radius: rem-calc(4px);
  }

  %table-cell-base {
    width: fit-content;
    padding: 0.75rem;
    border-right: rem-calc(1px) solid var(--color-border-default);
    border-left: rem-calc(1px) solid var(--color-border-default);
  }

  .TableHeaderCell {
    @extend %table-cell-base;

    font-weight: 500;
    vertical-align: middle;
    background-color: var(--color-background-upsell-default);
    border-bottom: 0;
  }

  .TableCell {
    @extend %table-cell-base;

    border-top: 0;
    border-bottom: 0;
  }

  &ButtonSection {
    @media (min-width: $breakpoint-s) {
      flex-direction: row;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: rem-calc(20px);
    border-top: $curve-2 solid $pds-color-neutral-100;

    &Caption {
      @media (min-width: $breakpoint-s) {
        text-align: left;
      }

      display: flex;
      justify-content: center;
      margin: rem-calc(12px);
    }

    &Icon {
      @media (min-width: $breakpoint-s) {
        width: rem-calc(24px);
        height: rem-calc(24px);
      }

      width: rem-calc(20px);
      height: rem-calc(20px);
    }

    &Button {
      @media (min-width: $breakpoint-s) {
        width: initial;
      }

      width: 100%;
    }
  }
}
