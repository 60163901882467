@import '../../../styles/reference';

.Spinner {
  text-align: center;

  svg > path {
    fill: var(--color-green-150);
  }
}

.EmptyList {
  margin-bottom: 0;
  color: $pds-grey-fg-light;
  text-align: center;
}

table.Table {
  width: 100%;
  margin-bottom: 0;
}

.EmptyValue {
  color: var(--color-neutral-400);
}

.Actions {
  overflow: visible;
}

.Card {
  padding: 0;
}

.CardPadding {
  padding: $spacing-m;

  @media (min-width: ($breakpoint-s)) {
    padding: $spacing-l;
  }
}

.DeleteAction {
  --bs-dropdown-link-color: #{$pds-color-red-700};
  --bs-dropdown-link-hover-color: #{$pds-color-red-700};
  --bs-dropdown-link-active-color: #{$pds-color-red-700};
}
