@import '../../../../styles/reference';

%shared-margin-bottom {
  margin-bottom: $spacing-l;

  @media (min-width: ($breakpoint-s)) {
    margin-bottom: $spacing-xl;
  }
}

.Section {
  &Container {
    display: flex;
    flex-direction: column;
    @extend %shared-margin-bottom;
  }

  &Body {
    & > div:not(:last-of-type) {
      margin-bottom: $spacing-m;
    }

    & > div:last-of-type {
      margin-bottom: 0;
    }
  }
}

.ProductBannerMargin {
  @extend %shared-margin-bottom;
}

.Skeleton {
  &Loader {
    display: block;
  }

  &ProductPageAlert {
    width: 100%;
    height: $spacing-l;
    margin: $spacing-l 0;
  }
}
