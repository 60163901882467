@import '../../../../styles/reference';

%bill-viewer {
  width: 100%;
  min-height: rem-calc(578px);
}

.BillViewer {
  &LoadingContainer {
    @extend %bill-viewer;

    background: $pds-color-white;
  }

  &OnErrorContainer {
    @extend %bill-viewer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $pds-grey-fg-light;
    font-weight: 400;
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    background-color: $pds-color-white;
    border: solid rem-calc(2px) $pds-grey-bg;
  }
}
