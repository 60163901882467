@import '../../../../styles/reference';
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-pseudo-class-no-unknown */
:global {
  :local(.BusinessLoanOfferBanner) {
    @media (min-width: $breakpoint-s) {
      .banner {
        &__info {
          align-self: start;
          width: 60%;
        }
      }
    }
  }
}
