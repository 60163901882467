/* TODO: After migration to the NX build taks need to reuse `libs/common-ui/src/lib/styles` instead */

/* You can add global styles to this file, and also import other style files */
@import './styles/reference';

@import '~@prospa/bootstrap/scss/root';
@import '~@prospa/bootstrap/scss/font-face';
@import '~@prospa/bootstrap/scss/reboot';
@import '~@prospa/bootstrap/scss/type';
@import '~@prospa/bootstrap/scss/buttons';
@import '~@prospa/bootstrap/scss/modal';
@import '~@prospa/bootstrap/scss/grid';
@import '~@prospa/bootstrap/scss/nav';
@import '~@prospa/bootstrap/scss/images';
@import '~@prospa/bootstrap/scss/transitions';
@import '~@prospa/bootstrap/scss/alert';
@import '~@prospa/bootstrap/scss/close';
@import '~@prospa/bootstrap/scss/utilities';
@import '~@prospa/bootstrap/scss/forms';
@import '~@prospa/bootstrap/scss/helpers';
@import '~@prospa/bootstrap/scss/dropdown';
@import '~@prospa/bootstrap/scss/tables';

/* Custom new design styles */
@import './styles/typography/typography';

/* Salt design system styles */
@import '@prospa/salt-tokens/css/styles.css';

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

main {
  width: 100%;
}

/* TODO: Temporary only */
.feature-not-ready {
  color: $pds-color-red-600;
}

section {
  width: 100%;
}

/* Remove blue backgroubnd on autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $pds-color-white inset !important;
}
