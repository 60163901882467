@import '../../../../styles/reference';

.too-many-replacement-cards-modal {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__icon {
    margin-bottom: $spacing-l;
  }

  &__subheading {
    margin-bottom: $spacing-l;
  }

  .pds-lead {
    margin-bottom: $spacing-m;
  }

  p {
    &:not(:last-child) {
      margin-bottom: $spacing-l;
    }

    margin-bottom: 0;
  }

  h4 {
    margin-bottom: $spacing-m;
  }

  .input-wrapper {
    margin-bottom: $spacing-l;
  }
}
