/* app width */
$app-max-width: 500px;

/* spacing tokens */
$spacing-xxs: rem-calc(2px);
$spacing-xs: rem-calc(4px);
$spacing-s: rem-calc(8px);
$spacing-m: rem-calc(16px);
$spacing-l: rem-calc(24px);
$spacing-xl: rem-calc(32px);
$spacing-xxl: rem-calc(40px);
$spacing-xxxl: rem-calc(48px);
$spacing-4xl: rem-calc(64px);
$spacing-5xl: rem-calc(80px);

/* breakpoints */
$breakpoint-xl: 1440px;
$breakpoint-l: 1280px;
$breakpoint-m: 1024px;
$breakpoint-s: 768px;
$breakpoint-ms: 576px;
$breakpoint-xxs: 428px;
$breakpoint-xs: 361px;

/* container-size */
$container-max-size: rem-calc(878px);
$mobile-min-width: rem-calc(361px);

/* border-radius */
$curve-2: rem-calc(2px);
$curve-4: rem-calc(4px);
$curve-6: rem-calc(6px);

/* line-height */
$content-line-height: rem-calc(24px);

/* Datepicker variables (copied over from Customer portal) */
$card-border: 1px solid $pds-grey-fg-lighter;

/* font weight */
$bolder-text: 600;

/* third party UI library class names */
$react-tabs: 'react-tabs';

/*
  Colours below aren't part of the design system
  but are used for things like shadows, focus rings, etc. as interim rebrand
*/
$outline-color: #dddfe1;

@keyframes fadeinmenu {
  0% {
    transform: translateY(-2rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
