@import '../../../../styles/reference';

.DesktopHeader {
  display: none;
  width: 100%;

  @media (min-width: $breakpoint-m) {
    display: flex;
    align-items: center;
    align-self: center;
    width: $container-max-size;
    padding: $spacing-l $spacing-m;
  }

  @media (min-width: $breakpoint-l) {
    padding-bottom: $spacing-l;
  }

  &LeftSection {
    display: flex;
    align-items: center;
    box-sizing: content-box;
    height: rem-calc(28px);
    padding: rem-calc(12px) 0;
    color: var(--color-neutral-500);
  }
}

.DesktopHeaderButton {
  &Wrapper {
    display: flex;
    align-items: center;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  &:global(.ba-button.iconlink) {
    color: var(--color-neutral-500);
    font-weight: $font-weight-normal;
  }
}
