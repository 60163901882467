@import '../../styles/reference';

.Card {
  margin-bottom: $spacing-l;
  padding: calc($spacing-l - $spacing-xxs);
  background-color: $pds-color-white;
  border: $spacing-xxs solid $pds-color-neutral-100;
  border-radius: $spacing-xs;

  @media (max-width: ($breakpoint-s - 1)) {
    padding: $spacing-m;
  }

  &.Gray {
    background: $pds-color-neutral-100;
  }

  &.Shadow {
    box-shadow: 0 0 15px rgba($pds-color-neutral-400, 0.3002);
  }

  .Divider {
    margin-top: $spacing-l;
    margin-bottom: $spacing-l;
    border-top: 2px solid $pds-color-neutral-100;
    opacity: 1;
  }
}
