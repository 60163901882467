@import '../../../styles/reference';

.payanyone-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  min-height: rem-calc(104px);
  padding: $spacing-l;
  background-color: $pds-color-white;
  border: $spacing-xxs solid $pds-color-neutral-100;
  border-radius: $spacing-xs;

  &[data-payee='true'] {
    align-items: flex-start;
  }

  &__add-new-payee-icon {
    path {
      fill: $pds-brand-secondary;
    }
  }

  @media (max-width: ($breakpoint-s - 1)) {
    min-height: rem-calc(82px);
    padding: $spacing-m;

    &__add-new-payee-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__dropdown-icon {
    display: flex;
    flex-shrink: 0;
    margin-top: $spacing-xs;
    margin-left: $spacing-l;
    cursor: pointer;

    path {
      fill: $pds-brand-secondary;
    }

    &[data-open='false'] {
      transform: rotate(180deg);
      transition: all 0.2s ease;
    }

    &[data-payee='true'] {
      align-self: center;
    }

    &-active {
      display: flex;
    }

    &-freeze {
      path {
        fill: $pds-color-neutral-500;
      }
    }
  }

  &__icon {
    display: flex;
    width: $spacing-xl;
    margin-right: $spacing-m;

    @media (max-width: ($breakpoint-s - 1)) {
      margin-right: $spacing-s;
    }
  }

  &__search {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;

    &-header {
      display: flex;
      margin-bottom: $spacing-xs;
      color: $pds-grey-fg-light;

      @media (min-width: $breakpoint-s) {
        margin-bottom: 0;
      }
    }

    &-input {
      width: 100%;
      padding: $spacing-xxs 0 $spacing-xs 0;
      border: none;
      border-bottom: 2px solid $pds-brand-cta;
      border-radius: 0;
      outline: none;
      animation: fadein 0.15s both ease-in;
      appearance: none;
    }

    &-placeholder {
      display: flex;
      width: 100%;
      color: $pds-grey-fg-light;
    }
  }

  &__search-icon {
    &-freeze {
      path {
        fill: $pds-color-neutral-500;
      }
    }
  }

  .hide {
    display: none;
  }

  &__menu {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    max-height: rem-calc(374px);
    margin-top: $spacing-s;
    overflow: auto;
    background: $pds-color-white;
    border-radius: $curve-2;
    box-shadow: 0 7px 24px rgba($pds-color-black, 0.3);
    animation: fadeinmenu 0.15s ease-in;
    padding-inline-start: 0;

    &-button {
      display: flex;
      width: 100%;
      border-bottom: 2px solid $pds-color-neutral-100;

      button {
        display: flex;
        margin: $spacing-m $spacing-l $spacing-m auto;
      }
    }

    &__option {
      display: flex;
      align-items: center;
      width: 100%;
      padding: $spacing-m $spacing-l;
      cursor: pointer;

      &-description {
        display: flex;
        flex-direction: column;
        margin-left: $spacing-m;
      }

      &-subheader {
        color: $pds-grey-fg-light;
      }

      &-delete {
        display: none;
        margin-left: auto;
      }

      &:not(:first-child, :last-child) {
        border-bottom: $spacing-xxs solid $pds-color-neutral-100;
      }

      &:hover {
        background-color: $pds-color-neutral-100;

        .payanyone-dropdown {
          &__menu {
            &__option {
              &-delete {
                display: flex;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: ($breakpoint-s - 1)) {
      & &__option {
        padding: $spacing-m;
      }
    }
  }

  &__container {
    position: relative;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    &__disabled {
      &:hover {
        cursor: initial;
      }
    }
  }
}
