@import '../../../styles/reference';

.code-input {
  &-error {
    color: $pds-color-red-600;
  }

  .react-code-input {
    /* override inline style from react-code-input */
    display: flex !important;
    justify-content: space-between;
    width: 100%;

    // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
    // disable up down arrow keys for input

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }

    /* Firefox */
    input[type='number'] {
      appearance: textfield;
    }

    input {
      width: rem-calc(56px);
      height: rem-calc(56px);
      margin-top: $spacing-s;
      margin-bottom: $spacing-s;
      text-align: center;
      border: 2px solid $pds-color-neutral-200;
      outline: none;

      &:focus-visible {
        border-bottom: 2px solid $pds-brand-cta;
      }
    }

    :last-child {
      margin-right: 0;
    }
  }
}
