@import '../../styles/reference';

.ExternalLinkContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: rem-calc(18px);
  padding: rem-calc(12px) $spacing-l;
  background-color: $pds-color-white;
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
}

.ExternalLinkRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: $spacing-m;
}

.OpenIcon {
  cursor: pointer;
}
