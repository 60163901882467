@import '../../../styles/reference';

.AccountList {
  &Card {
    margin-bottom: rem-calc(18px);
    padding: $spacing-m;

    @media (min-width: ($breakpoint-s)) {
      padding: $spacing-l $spacing-l $spacing-m;
    }
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  background-color: var(--color-background-default);
  border-radius: $curve-4;

  &Loader {
    padding: calc($spacing-m + rem-calc(1px)) $spacing-m;

    @media (min-width: ($breakpoint-s)) {
      padding: 0;
    }
  }

  &FooterButton {
    display: flex;
    justify-content: flex-start;
    width: calc(100% + ($spacing-l * 2));
    margin-left: -$spacing-l;
    padding: $spacing-m $spacing-l 0;
    border-top: $curve-2 solid $pds-color-neutral-100;

    @media (min-width: $breakpoint-s) {
      justify-content: flex-end;

      button {
        margin-left: $spacing-l;
      }
    }
  }
}

.AccountListItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: rem-calc(50px);
  background-color: var(--color-background-default);

  &:not(:first-of-type) {
    padding-top: $spacing-m;
  }

  &:not(:last-of-type) {
    padding-bottom: $spacing-m;
  }

  &:not(:first-child) {
    border-top: $curve-2 solid $pds-color-neutral-100;
  }

  @media (min-width: ($breakpoint-s)) {
    &:not(:first-of-type) {
      padding-top: $spacing-l;
    }

    &:not(:last-of-type) {
      padding-bottom: $spacing-l;
    }
  }

  &Left {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 90%;
    max-width: rem-calc(245px);
    @media (min-width: ($breakpoint-s)) {
      flex-direction: row;
      align-items: center;
      max-width: 100%;
    }
  }

  &Right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 10%;
  }

  &Details {
    display: grid;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 0;
    }
  }

  &Checkbox {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
    }

    @media (min-width: ($breakpoint-s)) {
      margin-right: $spacing-m;
    }
  }

  &Column {
    min-width: 100%;

    p {
      margin-bottom: 0;
    }

    @media (min-width: ($breakpoint-s)) {
      min-width: none;
    }
  }

  &Status {
    display: flex;
    min-width: rem-calc(84px);
    margin-top: 0;
    margin-left: 0;

    @media (min-width: ($breakpoint-s)) {
      min-width: rem-calc(68px);
      margin-top: $spacing-xs;
      margin-right: $spacing-l;
    }
  }

  &SyncTime {
    display: flex;
    box-sizing: content-box;
    padding-right: $spacing-l;
    color: var(--color-neutral-500);
    font-weight: $font-weight-normal;
    font-size: rem-calc(14px);
    @media (min-width: ($breakpoint-s)) {
      justify-content: end;
      min-width: rem-calc(240px);
    }
  }

  &Detail {
    display: none;

    @media (min-width: ($breakpoint-s)) {
      display: block;
      color: var(--color-neutral-500);
      font-size: rem-calc(14px);
    }
  }

  &Available {
    color: var(--color-neutral-500);
  }

  &Spinner {
    margin-right: $spacing-m;

    svg > path {
      fill: var(--color-green-150);
    }
  }
}
