@import '../../../../../styles/reference';

.AccountUnsyncConfirmModal {
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-s) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.card) {
    width: 100%;
  }

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .col > p {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: $spacing-m;
      }
    }
  }

  &Heading {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }

  &Body {
    height: 100%;
    border-bottom: 0;
  }

  &Footer {
    @media (min-width: $breakpoint-s) {
      margin-top: 0;
    }
  }

  &Account {
    margin-bottom: 0;
    color: $pds-color-neutral-900;
    font-weight: $bolder-text;
    text-align: center;

    @media (min-width: $breakpoint-s) {
      text-align: left;
    }
  }

  &Description {
    margin-bottom: 0;
    color: var(--color-text-default);
    text-align: center;

    @media (min-width: $breakpoint-s) {
      text-align: left;
    }
  }

  &Note {
    margin-bottom: $spacing-l;
    color: var(--color-text-subtlest);
    font-weight: $font-weight-normal;
    font-size: rem-calc(12px);
    line-height: 1.25rem;
  }

  &Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $spacing-m;

    @media (min-width: $breakpoint-s) {
      flex-direction: row;
    }

    div {
      flex: 1 1 0px;
    }
  }
}
