@import '../../../../styles/reference';

.cancel-scheduled-payments-modal {
  display: flex;
  flex-direction: column;

  &__icon {
    margin-bottom: $spacing-l;
  }

  p {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: $spacing-m;
  }
}
