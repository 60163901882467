@import '../../../styles/reference';

.NotificationsPreferences {
  &Header {
    margin-bottom: $spacing-l;
  }

  &Option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    & span {
      margin-right: auto;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &:not(:nth-last-child(1)) {
      margin-bottom: $spacing-l;
    }
  }

  &Divider {
    margin-top: $spacing-l;
    margin-bottom: $spacing-l;
    border-top: rem-calc(2px) solid $pds-color-neutral-100;
    opacity: 1;
  }
}
