@import '../../../../styles/reference';

.add-new-payee-modal {
  display: flex;
  width: 100%;
  margin-bottom: $spacing-xl;
  padding-top: $spacing-m;

  @media (min-width: $breakpoint-s) {
    padding-top: $spacing-xl;
  }

  // form section styling [mobile first]
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $breakpoint-s) {
      width: 50%;
    }

    .input-wrapper {
      margin-bottom: $spacing-l;
    }
  }

  .#{$react-tabs} {
    &.secondary-large {
      .#{$react-tabs}__tab-list {
        border: none;

        @media (min-width: $breakpoint-s) {
          margin-bottom: $spacing-xxl;
        }
      }

      .#{$react-tabs}__tab {
        background-color: $pds-color-white;
      }
    }

    &__tab {
      border: $spacing-xxs solid;
      border-color: $pds-color-neutral-100;

      &--selected {
        border-color: $pds-color-neutral-800;

        .pds-text {
          color: $pds-color-neutral-800;
        }
      }
    }
  }

  &-footer-message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: $pds-color-neutral-800;
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);

    #bills-bpay-inactivated-message {
      display: inline-block;
      padding-left: $spacing-xs;
    }

    @media (min-width: $breakpoint-s) {
      width: max-content;
    }
  }

  &__tab-header {
    display: flex;
    font-weight: 400;

    &__selected {
      font-weight: 600;
    }

    img {
      padding-right: $spacing-xs;
    }
  }
}
