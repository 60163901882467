@import '../../../../styles/reference';

.NewFeatureModal {
  &Header {
    position: relative;
    align-items: flex-start;
    height: 50%;
    min-height: 50%;
    padding: $spacing-m $spacing-m 0 $spacing-m;

    @media (min-width: ($breakpoint-s)) {
      height: rem-calc(323px);
      padding: $modal-inner-padding;
    }

    &Background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: cover;

      @media (min-width: ($breakpoint-s)) {
        background-size: 100%;
        background-origin: border-box;
      }
    }
  }

  &Body {
    padding: $spacing-m;

    &Heading {
      margin: $spacing-m 0;
      font-size: $h6-font-size;
    }

    &Text {
      font-size: $font-size-lg;
      line-height: rem-calc(28px);
    }

    @media (min-width: ($breakpoint-s)) {
      padding: 2.5rem;
    }
  }

  &Footer {
    padding: $spacing-m;

    @media (min-width: ($breakpoint-s)) {
      margin: 0;
      padding: 0 2.5rem 2.5rem;
    }
  }

  &Badge {
    @media (max-width: ($breakpoint-xxs - 1)) {
      position: absolute;
      top: 50%;
      left: 2.5rem;
      transform: translate(-50%, -50%);
    }
  }
}
