@import '../../../styles/reference';

.InformationTooltip {
  display: flex;
  align-items: baseline;
  width: max-content;
  margin-top: $spacing-s;
  color: $pds-color-neutral-800;

  &Title,
  &Content {
    margin-right: $spacing-s;
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }

  &Content {
    font-weight: $bolder-text;

    @media (min-width: $breakpoint-s) {
      font-size: rem-calc(16px);
      line-height: rem-calc(28px);
    }
  }

  &Loading {
    margin-bottom: $spacing-l;
  }

  &Title {
    font-weight: $font-weight-normal;
  }

  &Container {
    align-self: center;

    /* stylelint-disable-next-line selector-class-pattern */
    :global(.tooltip-info__text) {
      text-align: left;
    }

    > div {
      max-width: max-content;
    }

    img {
      margin-bottom: $spacing-xs;
    }
  }
}
