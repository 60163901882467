@import '../../../../styles/reference';

.Icon {
  margin-bottom: $spacing-l;
}

.Header {
  display: flex;
  align-items: flex-start;
}

.Description {
  letter-spacing: -0.176px;
}
