@import '../../../../styles/reference';

.Modal {
  &Body {
    justify-content: center;
  }

  &Footer {
    background: $pds-color-white;
  }
}

.MobileAppModal {
  position: absolute;
  bottom: 0;
  height: 90%;
  background: linear-gradient(to bottom, $pds-brand-grey-light 50%, $pds-color-white 50%) no-repeat;
  border-radius: $spacing-m $spacing-m 0 0;
  animation: slide-up-animation 0.75s ease-in-out;

  &Body {
    position: absolute;
    top: 44svh;
    width: 100%;
    padding: $spacing-l $spacing-m 0 $spacing-m;
    background-color: $pds-color-white;

    &Mockup {
      position: absolute;
      top: 2svh;
      z-index: -1;
      height: 42svh;
    }

    &Title {
      font-weight: 700;
      font-size: $spacing-xl;
      line-height: rem-calc(40px);
    }

    &Desc {
      font-size: rem-calc(14px);
    }
  }
}

@keyframes slide-up-animation {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
