@import '../../styles/reference';

.CVPCard {
  &__Heading {
    margin: $spacing-m 0;
    @media screen and (min-width: $breakpoint-m) {
      margin-top: 0;
    }
  }

  &__Card {
    @media screen and (min-width: $breakpoint-s) {
      margin-bottom: rem-calc(60px);
    }
  }

  &__Footer {
    display: block;

    @media screen and (min-width: $breakpoint-s) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }

    &_Button {
      width: 100%;
      margin-top: $spacing-l;
      font-size: rem-calc(16px) !important;

      @media screen and (min-width: $breakpoint-s) {
        width: auto;
      }
    }
  }
}
