@import '../../../styles/reference';

.RepaymentCalculator {
  &Form {
    &Grid {
      @media (min-width: ($breakpoint-s)) {
        grid-auto-rows: rem-calc(92px) rem-calc(92px);
        grid-template-columns: 1fr 1fr;

        &Dropdown {
          margin-top: rem-calc(4px);
        }
      }

      display: grid;
      grid-auto-rows: 0.25fr 0.5fr 0.5fr 0.25fr;
      grid-gap: rem-calc(16px) rem-calc(24px);
      grid-template-columns: auto;
    }
  }

  &CalculateButton {
    @media (min-width: ($breakpoint-s)) {
      width: rem-calc(164px);
    }

    width: 100%;
    margin: rem-calc(36px) 0 0 auto;
  }
}
