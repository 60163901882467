@import '../../../styles/reference';

.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: rem-calc(40px);

    @media (min-width: $breakpoint-s) {
      width: rem-calc(900px);
      height: fit-content;
    }
  }

  &--noheader {
    margin-top: rem-calc(100px);
  }

  h1 {
    margin-bottom: $spacing-l;
  }

  button {
    max-width: rem-calc(350px);
    margin-top: $spacing-s;
  }

  p {
    max-width: rem-calc(422px);
    margin-bottom: $spacing-l;
  }

  &__animation {
    width: rem-calc(135px);
    height: rem-calc(124px);
    margin-right: auto;
    margin-bottom: $spacing-xl;
    margin-left: auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: rem-calc(563px);
    text-align: center;

    &__text {
      color: $pds-grey-fg-light;
      font-size: rem-calc(14px);
    }
  }
}
