@import '../../../../styles/reference';

.stolen-card-modal {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__icon {
    margin-bottom: $spacing-l;
  }

  &__subheading {
    margin-bottom: $spacing-l;
  }

  h4 {
    margin-bottom: $spacing-m;
  }

  .input-wrapper {
    margin-bottom: $spacing-l;
  }
}
