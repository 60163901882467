@import '../../../styles/reference';

.paymentconfirmation {
  display: flex;
  flex-direction: column;

  &__icon {
    display: none;
    flex-shrink: 0;
    margin-right: $spacing-s;

    @media (min-width: ($breakpoint-s)) {
      display: block;
      margin-right: $spacing-m;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    padding-bottom: $spacing-l;
    border-bottom: $spacing-xxs solid $pds-color-neutral-100;

    button {
      align-self: auto;
      margin-left: auto;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-header {
      width: 100%;
      color: $pds-grey-fg-light;
    }

    &-row {
      display: flex;
      justify-content: space-between;
    }

    &-payer {
      display: flex;
      font-weight: $bolder-text;
      line-height: rem-calc(24px);
    }

    &-account {
      display: flex;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: $spacing-l 0;

    @media (min-width: ($breakpoint-s)) {
      margin-left: $spacing-xxl;
    }

    &-row {
      display: flex;

      p {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: $spacing-m;
      }

      &-heading {
        width: 38%;
        min-width: rem-calc(130px);
      }

      &-heading-value {
        overflow-wrap: anywhere;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    padding-top: $spacing-xxl;
    border-top: 2px solid $pds-color-neutral-100;

    @media (min-width: ($breakpoint-s)) {
      flex-direction: row;
    }

    &__text {
      margin-top: $spacing-l;
      color: $pds-grey-fg-light;
      font-size: rem-calc(14px);
      line-height: rem-calc(20px);

      @media (min-width: ($breakpoint-s)) {
        flex-direction: row;
        margin-top: 0;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;

      button {
        width: 100%;
      }

      button.secondary {
        border: 2px solid $pds-color-neutral-100;
        box-shadow: none;

        &:not(.disabled):hover {
          border: 2px solid $pds-color-neutral-900;
        }
      }

      button:first-child {
        border: 2px solid $pds-color-neutral-100;
      }

      button:last-child {
        margin-bottom: $spacing-m;
      }

      @media (min-width: ($breakpoint-s)) {
        flex-direction: row;

        button {
          width: max-content;
        }

        button:last-child {
          margin-bottom: 0;
          margin-left: $spacing-l;
        }
      }
    }
  }
}
