@import '../../../styles/reference';

.custom-statement {
  h3 {
    margin-bottom: $spacing-s;
  }

  &____text {
    margin-bottom: $spacing-l;
  }

  &__error {
    width: 100%;
    margin-top: $spacing-xs;
    color: $pds-color-red-600;
  }
}
