@import '../../../styles/reference';

.MissedRepayment {
  &Container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &SeeDetailsButton {
    display: flex;
    justify-content: center;
    text-underline-offset: 0.2em;
  }
}
