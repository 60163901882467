@import '../../../../styles/reference';

.DeferralLineOfCreditAlert {
  &Copy {
    margin-bottom: $spacing-m;
    @media (min-width: $breakpoint-s) {
      margin-bottom: 0;
    }
  }

  &Container {
    @media (min-width: $breakpoint-s) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
