@import '../../../styles/reference';

.RepaymentBreakdown {
  &Details {
    &Section {
      display: flex;
      flex-direction: column;
      gap: rem-calc(20px);
      padding: rem-calc(20px) 0;
      border-top: $curve-2 solid $pds-color-neutral-100;
    }

    &Label {
      @media (min-width: $breakpoint-xxs) {
        width: rem-calc(292px);
      }

      width: initial;
      font-weight: $bolder-text;
    }

    &Value {
      text-align: left;
    }

    &Pair {
      @media (min-width: $breakpoint-xxs) {
        justify-content: start;
      }

      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &ButtonSection {
    @media (min-width: $breakpoint-s) {
      flex-direction: row;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: rem-calc(20px);
    border-top: $curve-2 solid $pds-color-neutral-100;

    &Caption {
      @media (min-width: $breakpoint-s) {
        text-align: left;
      }

      display: flex;
      justify-content: center;
      margin: rem-calc(12px);
    }

    &Icon {
      @media (min-width: $breakpoint-s) {
        width: rem-calc(24px);
        height: rem-calc(24px);
      }

      width: rem-calc(20px);
      height: rem-calc(20px);
    }

    &Button {
      @media (min-width: $breakpoint-s) {
        width: initial;
      }

      width: 100%;
    }
  }
}
