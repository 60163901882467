@import '../../styles/reference';

$size: 35px;

.Spinner {
  position: relative;
  width: $size;
  height: $size;
  border-radius: 100%;

  .Circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border: calc($size / 10) solid transparent;
    border-radius: 100%;
    content: '';

    &.One {
      border-top-color: $pds-brand-cta;
      animation: half-circle-spinner-animation 1s infinite;
    }

    &.Two {
      border-bottom-color: $pds-brand-cta;
      animation: half-circle-spinner-animation 1s infinite alternate;
    }
  }

  &.White {
    .Circle {
      &.One {
        border-top-color: $pds-color-white;
      }

      &.Two {
        border-bottom-color: $pds-color-white;
      }
    }
  }

  &.Black {
    .Circle {
      &.One {
        border-top-color: $pds-color-neutral-800;
      }

      &.Two {
        border-bottom-color: $pds-color-neutral-800;
      }
    }
  }
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
