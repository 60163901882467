@import '../../../styles/reference';

.transaction-item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: $spacing-m 0;

  @media (min-width: $breakpoint-s) {
    margin-right: rem-calc(12px);
    margin-left: rem-calc(12px);
    padding: $spacing-l 0;
  }

  &__left {
    flex-shrink: 0;
  }

  &__right {
    flex-grow: 1;
    padding-left: $spacing-l;
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__hover-container {
    padding-right: $spacing-xs;
    padding-left: $spacing-xs;

    &:hover {
      background-color: $pds-color-neutral-100;
      border-radius: $spacing-xs;
      box-shadow: 0 rem-calc(-2px) $pds-color-neutral-100;
      cursor: pointer;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    font-size: $spacing-m;

    &__month {
      color: $pds-grey-fg-light;
      font-size: rem-calc(14px);

      @media (min-width: $breakpoint-s) {
        font-size: $spacing-m;
      }

      &.active {
        color: $pds-color-neutral-800;
      }
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    word-break: break-word;
  }

  &__values {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: flex-end;
    align-self: flex-start;
    margin-left: auto;
  }

  &__amount {
    .positive {
      color: $pds-color-green-700;
    }

    &__sign {
      color: $pds-color-red-600;
    }
  }

  &__status {
    color: $pds-grey-fg-light;

    &.active {
      margin-bottom: $spacing-m;
      color: $pds-color-neutral-800;
    }
  }

  &-container {
    padding-right: $spacing-s;
    padding-left: $spacing-s;

    &:not(:first-child, :last-child) {
      .transaction-item {
        border-bottom: $spacing-xxs solid $pds-color-neutral-100;
      }
    }

    &.expanded {
      box-shadow: inset $spacing-xs 0 $pds-brand-cta;
    }
  }

  &__failure {
    .pds-lead {
      color: $pds-color-red-600;
    }
  }

  &__description {
    color: $pds-grey-fg-light;

    &.active {
      margin-bottom: $spacing-m;
      color: $pds-color-neutral-800;
    }
  }

  &__expandDetails {
    & > div,
    & > p {
      margin-bottom: $spacing-s;
    }

    & > div:last-child,
    & > p:last-child {
      margin-bottom: 0;
    }
  }
}
