@import '../../../styles/reference';

.dashboard-buttons {
  display: flex;
  width: 100%;

  * {
    box-sizing: content-box;
  }

  &__button {
    &#{&} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      box-sizing: border-box;
      min-width: auto;
      height: rem-calc(48px);
      padding: $spacing-s $spacing-l;
      white-space: nowrap;

      @media (max-width: $breakpoint-s) {
        height: rem-calc(36px);
        margin-right: $spacing-s;
        padding: $spacing-s rem-calc(12px);
      }

      &.loading {
        justify-content: center;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  &__mobile-flex-grow {
    button {
      flex-grow: 1;
      @media (min-width: $breakpoint-s) {
        flex-grow: 0;
      }
    }
  }

  svg {
    width: rem-calc(20px);
    margin-right: $spacing-xs;

    @media (min-width: $breakpoint-s) {
      width: rem-calc(28px);
    }

    path {
      fill: $pds-color-neutral-900;
    }
  }

  button {
    margin-right: $spacing-m;

    @media (max-width: $breakpoint-ms) {
      margin-right: 0;
    }
  }

  @media (max-width: $breakpoint-ms) {
    justify-content: space-between;
  }
}

.dashboard-buttons-fixed {
  display: flex;
  width: 100%;
  margin-bottom: $spacing-xxl;

  @media (max-width: $breakpoint-s) {
    position: fixed;
    z-index: 1;
    padding-bottom: $spacing-m;
    overflow: auto;
    background-color: $pds-grey-bg-light;
    transition: 0.2s ease-in-out;
  }

  svg {
    width: rem-calc(20px);
    margin-right: $spacing-xs;

    @media (min-width: $breakpoint-s) {
      width: rem-calc(28px);
    }

    path {
      fill: $pds-color-neutral-900;
    }
  }

  button {
    margin-right: $spacing-m;

    @media (max-width: $breakpoint-s) {
      margin-right: $spacing-s;

      &:is(:last-child) {
        margin-right: $spacing-xxl;
      }
    }
  }
}
