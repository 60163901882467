@import '../../styles/reference';

.progress-bar {
  max-width: $app-max-width;
  height: rem-calc(5px);
  margin: 0 auto;
  background-color: $pds-color-green-50;
  border-radius: rem-calc(5px);

  &__progressed {
    height: 100%;
    background-color: $pds-color-green-300;
    border-radius: rem-calc(5px);
  }
}
