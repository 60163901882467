@import '../../../styles/reference';

.need-help {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-xl;
  color: $pds-grey-fg-light;
  font-size: rem-calc(16px);

  &__country {
    margin-top: $spacing-l;
  }
}
