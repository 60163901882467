@import '../../../styles/reference';

.balance-details {
  @media (min-width: ($breakpoint-s)) {
    margin-bottom: $spacing-l;
  }

  display: flex;
  align-items: center;
  min-height: rem-calc(94px);
  margin-bottom: $spacing-m;

  &__icon {
    display: none;
    align-self: flex-start;

    @media (min-width: ($breakpoint-s)) {
      display: flex;
      width: $spacing-xl;
      margin-top: $spacing-xs;
      margin-right: $spacing-m;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;

    @media (min-width: ($breakpoint-s)) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-description {
      &-mobile {
        @media (min-width: ($breakpoint-s)) {
          color: $pds-grey-fg-light;
        }
      }

      color: $pds-grey-fg-light;
    }

    &-row {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-account {
        margin-right: $spacing-s;
      }

      & span {
        margin-top: rem-calc(2px);
      }

      &-info {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .pds-text {
          padding-right: $spacing-s;
        }

        @media (max-width: $breakpoint-s) {
          .pds-summary-text {
            display: none;
          }
        }
      }

      &-details {
        font-weight: $bolder-text;
      }

      &:nth-of-type(2) {
        flex-direction: column;
        align-items: flex-end;

        & span {
          line-height: rem-calc(24px);
        }
        @media (max-width: ($breakpoint-s)) {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;

          & span {
            margin-top: $spacing-s;
            margin-right: $spacing-xs;
            font-size: rem-calc(12px);
            line-height: rem-calc(16px);
          }
        }
      }
    }

    &-freeze {
      color: $pds-color-neutral-500;

      svg {
        path {
          fill: $pds-color-neutral-500;
        }
      }
    }
  }
}

.balance-details-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: rem-calc(370px);
  height: rem-calc(90px);
  margin-bottom: rem-calc(40px);

  &__component {
    align-self: center;
  }
}
