@import '../../../styles/reference';

.Products {
  &Heading {
    margin: $spacing-l 0;

    @media screen and (min-width: $breakpoint-m) {
      margin-top: 0;
    }
  }

  &HeadingInfo {
    margin-bottom: $spacing-xl;
    font-weight: 400;
    font-size: rem-calc(16px);
  }

  &SubHeading {
    margin-bottom: $spacing-m;
    font-weight: $bolder-text;
    font-size: rem-calc(20px);
  }

  &Modal {
    &Alert {
      color: $pds-color-neutral-900;
      background-color: $pds-color-neutral-100;
      border: $spacing-xxs solid $pds-color-neutral-300;
    }
  }
}
