@import '../../../styles/reference';

.PayAnyoneSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem-calc(84px);
}

.PayAnyonePadding {
  padding: $spacing-l;
}
