@import '../../../styles/reference';

.LoadingBox {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $pds-color-neutral-200;
  border-radius: $spacing-xs;

  &::after {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(
      90deg,
      rgba($pds-color-white, 0) 0,
      rgba($pds-color-white, 0.2) 20%,
      rgba($pds-color-white, 0.5) 60%,
      rgba($pds-color-white, 0)
    );
    transform: translateX(-100%);
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
