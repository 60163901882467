@import '../../../styles/reference';

:global {
  :local(.ClassicCardBanner) {
    /* stylelint-disable selector-class-pattern */
    &.banner[class^='Card'] {
      position: relative;
      background-color: var(--color-background-upsell-default);
      border: 2px solid $pds-grey-light;
      border-radius: rem-calc(4px);
    }

    .banner {
      &__info {
        &-heading {
          font-size: rem-calc(16px);
        }
      }

      &__info-icon {
        align-self: flex-start;
        order: 1;
        margin-right: $spacing-m;

        img {
          width: rem-calc(28px);
        }
      }
    }

    @media (min-width: $breakpoint-s) {
      .banner {
        &__info {
          align-self: start;
        }

        &__info-icon {
          order: 0;
        }

        &__close-btn {
          position: absolute;
          top: rem-calc(12px);
          right: rem-calc(12px);

          svg {
            width: rem-calc(20px);
            height: rem-calc(20px);
          }
        }
      }

      button.banner__btn {
        align-self: flex-end;
        min-width: auto;
      }
    }
  }
}
